import { createContext, useContext, useEffect, useState } from "react"
import { useContextex } from "./useContext";
import { CustomSnackbar } from "../utils/CustomSnackbar";

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const { setLoginUserData } = useContextex();
    const [isAuth, setIsAuth] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    useEffect(() => {
        const token = localStorage.getItem('authToken')
        if (token) {
            setIsAuth(true);
        }
    }, [])

    const login = (token) => {
        localStorage.setItem('authToken', token);
        setIsAuth(true);
    }

    const logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('uid');
        localStorage.removeItem('loginUser');
        setLoginUserData(null)
        setIsAuth(false);
        setShowSnackbar(true)
    };

    return (
        <>
            <AuthContext.Provider value={{ isAuth, login, logout }}>
                {children}
            </AuthContext.Provider>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={"Logout Successfull.."} />}
        </>

    )

}