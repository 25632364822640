import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useContextex } from '../context/useContext';
import { fetchData } from '../store/action';
import { ReModal } from '../partials/ReModal';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getData } from '../store/selector';


const cancleBookingData = [
    { id: '1', title: 'Financing fell through' },
    { id: '2', title: 'Inspection issues' },
    { id: '3', title: 'Change in financial situation' },
    { id: '4', title: 'Title issues' },
    { id: '5', title: 'Seller changes their mind' },
    { id: '6', title: 'Competing offer' },
    { id: '7', title: 'Personal Reasons' },
    { id: '8', title: 'Others' },
]

function Cancel() {
    const [selectedReason, setSelectedReason] = useState(null);
    const [customReason, setCustomReason] = useState('');
    const [isCancle, setIsCancle] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const data = useSelector(getData)

    const { isUserId, bookingId, showCancelModal, setShowCancelModal } = useContextex();

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            if (isCancle) {
                try {
                    await dispatch(fetchData('u_my_book_cancle.php?', { book_id: bookingId, uid: isUserId, cancle_reason: selectedReason === 'Others' ? customReason : selectedReason }));
                    setIsCancle(false);
                    setShowSnackbar(true);
                    setShowCancelModal(false)
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, selectedReason, bookingId, isUserId, isCancle, customReason]);

    useEffect(() => {
        if (data) {
            // Handle data logic here
        }
    }, [data]);

    useEffect(() => {
        if (showCancelModal) {
            setIsCancle(false);
        }
    }, [showCancelModal]);

    const closeModal = () => setShowCancelModal(false);
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };


    return (
        <>
            <ReModal isOpenModal={showCancelModal} onClose={closeModal}>
                <div className="image-left">
                    <img src="./../assets/images/section/login.jpg" alt="" />
                    <h3>{t('Welcome to Your Real Estate Website')}</h3>
                </div>

                <div className="content-right p-5 ">
                    <form className='form-bacsic-infomation flex  flex-column'>
                        <h4 className='d-flex justify-content-center'>{t('Cancel Booking Request')}</h4>
                        <ul className="flex flex-column gap15">
                            {cancleBookingData?.map((item) => (
                                <li className="radio-item" key={item?.id}>
                                    <label>
                                        <p >{item?.title}</p>
                                        <input type="radio" name="flexRadioDefault" id={`flexRadioDefault${item.id}`} checked={selectedReason === item?.title} onChange={() => setSelectedReason(item.title)} />
                                        <span className="btn-checkbox"></span>
                                    </label>
                                </li>
                            ))}

                        </ul>
                        {selectedReason === 'Others' && (

                            <fieldset className="name  ">
                                <input type="text" className='w-100 ' id="galleryCategoryName" value={customReason} onChange={(e) => setCustomReason(e.target.value)} name="prop_title" placeholder="Enter Reason" required="required" />
                            </fieldset>

                        )}

                        <div className='col-12 mt-5 d-flex justify-content-between'>

                            <a className="tf-button-primary style-black active mx-2" onClick={() => setShowCancelModal(false)}>{t('Cancel')} </a>
                            <a className="tf-button-primary active" onClick={() => setIsCancle(true)} >{t('Confirm')} <i className="icon-arrow-right-add"></i></a>

                        </div>
                    </form>
                </div>
            </ReModal>
             {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={data?.ResponseMsg} />}
        </>
    )
}

export default Cancel
