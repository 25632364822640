import React, { useEffect } from 'react';
import axios from 'axios';

export const MidtransPayment = ({ product_amount }) => {
  const clientKey = 'SB-Mid-client-jsBzAGzoAL0sml3M'; // Replace with your Midtrans Client Key
  const serverKey = 'SB-Mid-server-b4bO6W6a8GP9rqrEFKVzUn5G'; // Replace with your Midtrans Server Key
  const orderId = Date.now();

  useEffect(() => {
    const initializePayment = async () => {
      try {
        // Construct the request payload
        const payload = {
          transaction_details: {
            order_id: orderId.toString(),
            gross_amount: product_amount,
          },
          credit_card: {
            secure: true,
          },
        };

        // Make API request to Midtrans
        const response = await axios.post(
          'https://api.sandbox.midtrans.com/v2/charge',
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${btoa(serverKey + ':')}`,
            },
          }
        );

        // Redirect to Midtrans payment page
        window.location.href = response.data.redirect_url;
      } catch (error) {
        console.error('Error initiating payment:', error);
        // Handle error (e.g., display error message to user)
      }
    };

    if (product_amount) {
      initializePayment();
    }
  }, [product_amount, orderId, serverKey]);

  return <div>Initiating payment...</div>;
};

