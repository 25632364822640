import React, { useEffect, useState } from 'react';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { uid } from 'uid';
import { AddToProductPay, AddToWallet } from './AddToWallet';
import AddProparty from '../addProparty/AddProparty';
import { useNavigate } from 'react-router-dom';

export const RazorpayPayment = ({ product_amount, booked_for }) => {
  const { bookedOtherUserData, loginUserData, setTransactionId, transactionId, selectedPackage, productAmount, productFinalPrice } = useContextex();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate()
  const snack_message = 'your transaction is Successfull'
  // const uid = uid(15)
  const loadRazorpay = () => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => {
      const options = {
        key: 'rzp_test_oyEnEu70MpsDyP',
        amount: product_amount * 100,
        currency: 'INR',
        name: 'Go Property',
        description: 'Test Transaction',
        image: 'https://yourlogo.com/logo.png',
        handler: function (response) {
          setShowSnackbar(true);
          console.log(response);
          setTransactionId(response?.razorpay_payment_id)
          
          if(booked_for == 'booking'){
            window.location.href = '/dashboard';
          }
          if(booked_for == 'package'){
            navigate('/addProparty')
          }
        },
        prefill: {
          name: bookedOtherUserData?.firstName ? bookedOtherUserData.firstName : loginUserData?.UserLogin?.name,
          email: bookedOtherUserData?.email ? bookedOtherUserData.email : loginUserData?.UserLogin?.email,
          contact: bookedOtherUserData?.phone ? bookedOtherUserData.phone : loginUserData?.UserLogin?.mobile,
        },
        notes: {
          address: 'Corporate Office',
        },
        theme: {
          color: '#3399cc',
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    loadRazorpay()
  }, [])

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <>
      {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snack_message} />}
      {booked_for == 'wallet' && transactionId && <AddToWallet/>}
      {booked_for == 'booking' && transactionId && <AddToProductPay/>}
      {booked_for == 'package' && transactionId && <AddProparty/>}
    </>
  );

};
