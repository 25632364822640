import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { AppProvider } from './context/AppContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme();  

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <AppProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AppProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
