import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../store/action';
import { useContextex } from '../context/useContext';
import { useTranslation } from 'react-i18next';
import { getData, getLoading, getSecoundData, getSendEnquiryData } from '../store/selector';

function InquiryPage({ setSendEReq }) {

    const [enquryData, setEnquryData] = useState(null)

    const { productDetailId, isUserId, bookedProductData } = useContextex();
    const dispatch = useDispatch();

    const data = useSelector(getSendEnquiryData)
    const loading  = useSelector(getLoading);
    const { t } = useTranslation();
    const handleSendRequest = useCallback(async (event) => {
        event.preventDefault();

        const formData = {
            uid: isUserId,
            prop_id: productDetailId,
        };

        const endpoint = 'u_enquiry.php';

        try {
            await dispatch(fetchData(endpoint, formData));
            setSendEReq(true)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [isUserId, productDetailId, dispatch]);

    useEffect(() => {
        if (data) {
            setEnquryData(data);
        }
    },[data])

    const isEnquiry = bookedProductData?.propetydetails?.is_enquiry;
    const isButtonDisabled = isEnquiry === 1;
    const buttonText = isEnquiry === 0
        ? `${t('Contact a Property Owner')} <i className="icon-arrow-right-add"></i>`
        : `${t('Contacted')}`;
    const buttonClass = `tf-button-primary w-full justify-content-center d-flex ${isEnquiry === 0 ? 'style-bg-white' : ''}`;

    return (
        <>
            <div className="schedule">
                <h4 className="wow fadeInUp justify-content-center d-flex">{t('Inquiry Status')}</h4>
                <div className="button-submit mt-5">
                    <button
                        disabled={isButtonDisabled}
                        className={buttonClass}
                        onClick={handleSendRequest}
                        dangerouslySetInnerHTML={{ __html: buttonText }}
                    />
                </div>
            </div>
            {loading && (
                <div className="preload preload-container">
                    <div className="middle"></div>
                </div>
            )}
        </>
    );
}

export default InquiryPage;
