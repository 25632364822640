import React, { useEffect, useState } from 'react';

export const PaytmPayment = ({ product_amount }) => {
    const [loading, setLoading] = useState(false);
    const [paymentData, setPaymentData] = useState({
        orderId: '',
        txnToken: '',
        mid: 'TxCado20711191599835', // Replace with your actual Merchant ID
        amount: product_amount,
    });

    useEffect(() => {
        loadPaytmCheckoutJS();
    }, []);

    const loadPaytmCheckoutJS = () => {
        const script = document.createElement('script');
        script.src = 'https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/TxCado20711191599835.js'; // Replace with your Merchant ID
        script.async = true;
        script.onload = () => {
            console.log('Paytm CheckoutJS loaded successfully');
        };
        script.onerror = (error) => {
            console.error('Error loading Paytm CheckoutJS:', error);
        };

        document.body.appendChild(script);
    };

    const initializePayment = async () => {
        setLoading(true);
        try {
            const response = await mockFetchTransactionData();
            setPaymentData({
                ...paymentData,
                orderId: response.orderId,
                txnToken: response.txnToken,
            });
            makePayment();
        } catch (error) {
            console.error('Error initializing payment:', error);
        } finally {
            setLoading(false);
        }
    };

    const makePayment = () => {
        const config = {
            root: '',
            style: {
                bodyBackgroundColor: '#fafafb',
                themeBackgroundColor: '#0FB8C9',
                headerBackgroundColor: '#284055',
                themeColor: '#ffffff',
                headerColor: '#ffffff',
            },
            data: {
                orderId: paymentData.orderId,
                token: paymentData.txnToken,
                tokenType: 'TXN_TOKEN',
                amount: paymentData.amount,
            },
            payMode: {
                order: ['CC', 'DC', 'NB', 'UPI', 'PPBL', 'PPI', 'BALANCE'],
            },
            website: 'WEBSTAGING', // Use 'WEB' for production
            flow: 'DEFAULT',
            merchant: {
                mid: paymentData.mid,
                redirect: false,
            },
            handler: {
                transactionStatus: function (paymentStatus) {
                    console.log('Payment Status:', paymentStatus);
                },
                notifyMerchant: function (eventName, data) {
                    
                },
            },
        };

        if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.init(config)
                .then(() => {
                    window.Paytm.CheckoutJS.invoke();
                })
                .catch((error) => {
                    console.error('Error initializing Paytm CheckoutJS:', error);
                });
        } else {
            console.error('Paytm.CheckoutJS is not available');
        }
    };

    const mockFetchTransactionData = async () => {
        // Simulate network request
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    orderId: generateOrderId(),
                    txnToken: Date.now() // Replace with actual token
                });
            }, 1000); // Simulate network delay
        });
    };

    const generateOrderId = () => {
        // Generate a unique order ID   
        return `ORDER_${Math.floor(Math.random() * 1000000)}`;
    };

    useEffect(() => {
        initializePayment()
    },[])
    return (
        <div>
            {loading &&
                <p>Loading...</p>
            }

        </div>
    );
};
