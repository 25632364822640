import React, { useEffect, useMemo } from 'react'
import { fetchData } from '../store/action';
import { useDispatch, useSelector } from 'react-redux';
import { useContextex } from '../context/useContext';

function PaymentList() {
    const dispatch = useDispatch()
    const uPaymentListSelector = useMemo(
        () => (state) => state.data,
        []
    );
    
    const { setPaymentGatwayList } = useContextex();

    const data = useSelector(uPaymentListSelector);
    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchData('u_paymentgateway.php'));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch]);

    useEffect(() => {
        if (data) {
            setPaymentGatwayList(data?.paymentdata);
        } else {
            console.error("Data or payment data is null or undefined.");
        }
    }, [data]);
}

export default PaymentList
