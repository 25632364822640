import React, { useEffect, useState } from 'react';
import { PaystackButton } from 'react-paystack';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { AddToProductPay, AddToWallet } from './AddToWallet';

export const PaystackPayment = ({ product_amount, booked_for }) => {
    const { productAmount, bookedOtherUserData, loginUserData, setTransactionId, transactionId, selectedPackage, productFinalPrice } = useContextex();
    const [componentProps, setComponentProps] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const snack_message = 'your transaction is Successfull'

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const publicKey = 'pk_test_71d15313379591407f0bf9786e695c2616eece54';


    useEffect(() => {
        const email = bookedOtherUserData?.email ? bookedOtherUserData.email : loginUserData?.UserLogin?.email;
        const amount = product_amount * 100;
        const first_name = bookedOtherUserData?.firstName ? bookedOtherUserData.firstName : loginUserData?.UserLogin?.name;
        const last_name = bookedOtherUserData?.lastname ? bookedOtherUserData.lastname : loginUserData?.UserLogin?.name;
        const phone = bookedOtherUserData?.phone ? bookedOtherUserData.phone : loginUserData?.UserLogin?.mobile;

        const props = {
            email,
            amount,
            first_name,
            last_name,
            phone,
            publicKey,
            text: 'Pay Now',
            onSuccess: (response) => {
                setTransactionId(response?.transaction);
                if (booked_for == 'booking' && transactionId ) {
                    window.location.href = '/dashboard';
                }
                setShowSnackbar(true);
            },
            onClose: () => {
                console.log('Payment closed');
            }
        };

        setComponentProps(props);
    }, [bookedOtherUserData, loginUserData, productAmount, selectedPackage, setTransactionId]);

    if (!componentProps) {
        return null;
    }

    return (
        <div>
            <PaystackButton {...componentProps} />
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snack_message} />}
            {booked_for == 'wallet' && transactionId && <AddToWallet />}
            {booked_for == 'booking' && transactionId && <AddToProductPay />}
        </div>
    );
};

