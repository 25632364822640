import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useContextex } from '../context/useContext';
import { fetchBookedProductData, fetchData } from '../store/action';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { getSecoundData } from '../store/selector';

export const AddToWallet = () => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    
    const snack_message = 'your transaction is Successfull'
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const { isUserId, transactionId, walletAddAmount, setWalletAmount} = useContextex();
    const dispatch = useDispatch();

    useEffect(() => {
        if (transactionId && walletAddAmount) {

            const fetchDataAndSetCategories = async () => {
                try {
                    await dispatch(fetchData('u_wallet_up.php', { uid: isUserId, wallet: walletAddAmount }));
                    setWalletAmount('')
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchDataAndSetCategories();
        }
    }, [transactionId, dispatch, isUserId, walletAddAmount]);

    return (
        <>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snack_message} />}
        </>
    );
}

export const AddToProductPay = ({ paybleWalletAmnt }) => {
    const [bookedProductDetails, setBookedProductDetails] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const navigate = useNavigate()
    
    const {
        bookedUserData,
        productAmount,
        bookedProductData,
        transactionId,
        selectedPaymentId,
        isUserId,
        productFinalPrice,
        bookedOtherUserData,
        otherUserGender 
    } = useContextex();

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const secoundData = useSelector(getSecoundData);
    const dispatch = useDispatch();
    const productTax = (productAmount?.bookingAmount) * (productAmount?.bookingTax) / 100 

    const booking_success_data = {
        uid: isUserId,
        total: productFinalPrice,
        prop_id: bookedProductData?.propetydetails?.id,
        check_in: bookedUserData?.checkIn,
        check_out: bookedUserData?.checkOut,
        subtotal: productAmount?.bookingAmount,
        total_day: productAmount?.bookingDays,
        cou_amt: bookedUserData?.bookingCouponAmount || '0',
        wall_amt: paybleWalletAmnt || '0',
        transaction_id: (selectedPaymentId === '2' || selectedPaymentId === '5') ? '0' : transactionId,
        add_note: bookedUserData?.notes,
        prop_price: bookedProductData?.propetydetails?.price,
        book_for: bookedUserData?.bookedFor,
        p_method_id: selectedPaymentId,
        tax: productTax,
        noguest: bookedUserData?.noGuest,
        ...(bookedUserData?.bookedFor == 'other' && {
            fname: bookedOtherUserData?.firstName,
            lname: bookedOtherUserData?.lastName,
            gender: otherUserGender,
            email: bookedOtherUserData?.email,
            mobile: bookedOtherUserData?.phone,
            ccode: '+91',
            country: 'india'
        })
    };
    
    useEffect(function fetchDataAndSetCategories() {
        const fetchData = async () => {
            try {
                await dispatch(fetchBookedProductData(booking_success_data));
                setShowSnackbar(true);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [transactionId, dispatch]);

    useEffect(function updateBookedProductDetails() {
        const updateDetails = () => {
            if (secoundData) {
                setBookedProductDetails(secoundData);
                if(selectedPaymentId == '5' || '2') {
                    navigate('/dashboard')
                }                
            } else {
                console.error("Payment details are null or undefined.");
            }
        };

        updateDetails();
    }, [secoundData]);

    const snack_message = bookedProductDetails?.ResponseMsg

    return (
        <>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snack_message} />}
        </>
    );
}

