import './App.css';
import Header from './partials/Header';
import Footer from './partials/Footer';
import Main from './partials/Main';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ProductDetails } from './product/ProductDetails';
import { Dashboard } from './dashboard/Dashboard';
import AddProparty from './addProparty/AddProparty';
import Receipt from './addProparty/Receipt';
import { CartProduct } from './product/CartProduct';
import AllProduct from './product/AllProduct';
import { AuthProvider } from './context/AuthContext';
import { useAuth, useContextex } from './context/useContext';
import PackagePurchase from './booking/PackagePurchase';
import PrivacyPolicy from './partials/PrivacyPolicy';
import TermsAndCondi from './partials/TermsAndCondi';
import ContactUs from './partials/ContactUs';
import { Faqlist } from './partials/Faqlist';

// PrivateRoute component to handle authentication
const PrivateRoute = ({ element }) => {
  const { isAuth } = useAuth();
  return isAuth ? element : <Navigate to="/" />;
};


function App() {

  const { isAdmin } = useContextex();

  return (
    <AuthProvider>
      <Router>
        <div className="body counter-scroll">
          <div id="wrapper">
            <div id="page">
              <Header /> {/* Ensure this is within Router */}
              <Routes>
                {/* Public Routes */}
                {isAdmin ? (
                  <>
                    <Route path="/" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="/:title" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="/:title/add" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="/:title/complated-booking" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="/:title/current-booking" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="receipt" element={<PrivateRoute element={<Receipt />} />} />
                  </>
                ) : (
                  <>
                    <Route path="/" element={<Main />} />
                    <Route path="/:pid" element={<ProductDetails />} />
                    <Route path="/product-add" element={<AllProduct />} />
                    <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                    <Route path="/terms_and_condition" element={<TermsAndCondi />} />
                    <Route path="/contact_us" element={<ContactUs />} />
                    <Route path="/faq_list" element={<Faqlist />} />
                    {/* Private Routes */}

                    <Route path="/product-cart" element={<PrivateRoute element={<CartProduct />} />} />
                    <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
                    <Route path="/dashboard/:name" element={<PrivateRoute element={<Dashboard />} />} />
                    <Route path="/package_purchase" element={<PrivateRoute element={<PackagePurchase />} />} />

                    <Route path="/addProparty" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="/addProparty/:title" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="/addProparty/:title/add" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="/addProparty/:title/complated-booking" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="/addProparty/:title/current-booking" element={<PrivateRoute element={<AddProparty />} />} />
                    <Route path="/receipt" element={<PrivateRoute element={<Receipt />} />} />
                  </>
                )}
                {/* Page Not Found */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              {/* <Footer /> */}
            </div>
          </div>
          <div className="progress-wrap active-progress">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style={{ transition: 'stroke-dashoffset 10ms linear 0s', strokeDasharray: '307.919, 307.919; stroke-dashoffset: 286.138' }}></path>
            </svg>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
