import { IconArrowBackUp, IconCalendarMonth, IconCopy, IconHeart, IconHeartFilled, IconLocation, IconMap2, IconMessageCircle2, IconNotification, IconPackage, IconUser, IconWallet } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useContextex } from '../context/useContext';
import { fetchData, fetchEditProUser, fetchFavProdList, fetchPackageData, fetchSecoundData, fetchWalletData, baseUrl } from '../store/action';
import axios from 'axios';
import Receipt from '../addProparty/Receipt';
import Cancel from '../booking/Cancel';
import PaymentList from '../payment/PaymentList';
import { KhaltiPayment } from '../payment/KhaltiPayment';
import { FlutterwavePayment } from '../payment/FlutterwavePayment';
import { PaystackPayment } from '../payment/PaystackPayment';
import { RazorpayPayment } from '../payment/RazorpayPayment';
import { PaytmPayment } from '../payment/PaytmPayment';
import { PaypalPayment } from '../payment/PaypalPayment';
import { StripePayment } from '../payment/StripePayment';
import { PayfastPayment } from '../payment/PayfastPayment';
import { ReModal } from '../partials/ReModal';
import SenangpayPayment from '../payment/SenangpayPayment';
import { MercadoPagoPayment } from '../payment/MercadoPagoPayment';
import { MidtransPayment } from '../payment/MidtransPayment';
import { TwoCheckoutPayment } from '../payment/TwoCheckoutPayment';
import $ from 'jquery'
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getData, getPackageData, getProfileEditData, getSecoundData, getUserFavListData, getWalletReportData } from '../store/selector';

const iconStyle = {
    color: 'red',
};

export const Dashboard = () => {
    const side_bar_data = [
        { name: 'My Booking', icon: IconCalendarMonth, nav: 'my_booking' },
        { name: 'Wallet', icon: IconWallet, nav: 'wallet' },
        { name: 'Chat', icon: IconMessageCircle2, nav: 'chat' },
        { name: 'Profile', icon: IconUser, nav: 'profile' },
        { name: 'My Favorite', icon: IconHeart, nav: 'my_favorite' },
        { name: 'My Package', icon: IconPackage, nav: 'my_package' },
        { name: 'Notification', icon: IconNotification, nav: 'notifications' },
        { name: 'Country', icon: IconMap2, nav: 'country' },
        { name: 'Invite Friends', icon: IconLocation, nav: 'invite_friends' },
    ];

    const [currentUser, setCurrentUser] = useState(null)

    const [dashboardData, setDashboardData] = useState(null)
    const [responseMsg, setResponseMsg] = useState(null)

    const [isCanvasActive, setIsCanvasActive] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const [activeName, setActiveName] = useState('My Booking');

    const data = useSelector(getData)
    const secoundData = useSelector(getSecoundData)
    const packageDt = useSelector(getPackageData)
    const proEdit = useSelector(getProfileEditData)

    const dispatch = useDispatch();
    const { name } = useParams()
    const { t } = useTranslation();

    const { isUserId, imageBaseUrl, loginUserData, currentPage, setCurrentPage, userCurrency, selectedCountryId, showCancelModal, setSelectedCountryId, setCountryListData } = useContextex();

    const upass = loginUserData?.UserLogin?.password

    useEffect(() => {
        setCurrentPage('dash-board')
    }, [currentPage])

    useEffect(() => {
        var tabs = function () {
            $('.widget-tabs').each(function () {
                $(this).find('.widget-content-tab').children().hide();
                $(this).find('.widget-content-tab').children(".active").show();
                $(this).find('.widget-menu-tab').children('.item-title').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive);
                    contentActive.addClass('active').fadeIn("slow");
                    contentActive.siblings().removeClass('active');
                    $(this).addClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive).siblings().hide();
                });
            });
            $('.widget-tabs-1').each(function () {
                $(this).find('.widget-content-tab-1').children().hide();
                $(this).find('.widget-content-tab-1').children(".active-1").show();
                $(this).find('.widget-menu-tab-1').children('.item-title-1').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive);
                    contentActive.addClass('active-1').fadeIn("slow");
                    contentActive.siblings().removeClass('active-1');
                    $(this).addClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive).siblings().hide();
                });
            });
        };

        return tabs()
    }, [])


    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                let endpoint = '';
                switch (activeName) {

                    case 'My Package':
                        endpoint = 'u_sub_details.php';
                        break;
                    case 'Notification':
                        endpoint = 'notification.php';
                        break;
                    case 'Country':
                        endpoint = 'u_country.php';
                        break;
                    case 'Invite Friends':
                        endpoint = 'u_getdata.php';
                        break;
                    default:
                        break;
                }
                await dispatch(fetchData(endpoint, { uid: isUserId }));

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, activeName, isUserId]);

    useEffect(() => {
        if (data) {
            setDashboardData(data);
        }
    }, [data]);

    useEffect(() => {
        setCurrentUser({ id: isUserId });
    }, [isUserId, setCurrentUser]);

    const handleItemClick = (name) => {
        setActiveName(name);
    };

    useEffect(() => {
        const defaultCity = dashboardData?.CountryData?.find(country => country?.d_con == '1')
        if (!selectedCountryId) {
            setSelectedCountryId(defaultCity?.id)
        }
    }, [dashboardData])

    const handleCountryClick = (itemId) => {
        setSelectedCountryId(itemId);
        const selectedCountry = dashboardData?.CountryData?.find(country => country?.id === itemId);
        if (selectedCountry) {
            setCountryListData([selectedCountry]);
        }
    };

    const handleCanvasClick = () => {
        setIsCanvasActive(!isCanvasActive);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    return (
        <>
            <div id="wrapper">

                <div id="page" className="layout-wrap background-F9F9F9">

                    <div className="main-content spacing-20">

                        <div className="layout-wrap-inner">

                            <div className={`section-menu-left ${isCanvasActive ? 'null' : ''}`}>
                                <div className="menu-content">
                                    <ul>

                                        {side_bar_data?.map((item, index) => (
                                            <Link to={`${activeName === 'Dashboard' ? '/dashboard' : `/dashboard/${item.nav}`}`} className='py-0 px-0 mb-3 w-100 ' key={index}>
                                                <li className={`pointer w-100 ${activeName === item?.name ? 'active' : ''}`} onClick={() => handleItemClick(item.name)}>
                                                    <a>{<item.icon />}{`${item?.name}`}</a>
                                                </li>
                                            </Link>
                                        ))}

                                    </ul>
                                </div>
                            </div>

                            <div className={`section-content-right ${isCanvasActive ? 'full' : ''}`}>

                                {activeName === 'My Booking' && (
                                    <UserBookingSec activeName={activeName} />
                                )}
                                {activeName === 'Profile' && (
                                    <UserProfileSec />
                                )}
                                {activeName === 'My Favorite' && (
                                    <FavoriteList />
                                )}
                                {activeName === 'My Package' && (
                                    <>
                                        <div className='d-flex mob-dash flex-col pt-5 pb-5'>
                                            <div className='col-10'>
                                                <h3>{t('My Package')}</h3>
                                                <div className="text-content">{t('Your Current Wallet Balance is')}</div>
                                            </div>
                                        </div>

                                        <div className="wg-box pl-44 pr-29" style={{ height: '100vh' }}>
                                            <div className="table-text-infor default mb-40">
                                                <div className="head">
                                                    <div className="item">
                                                        <div className="text">{t('Order ID')}</div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="text">{t('Package')}</div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="text">{t('Amount')}</div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="text">{t('Date')}</div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="text">{t('Payment Mode')}</div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="text">{t('Days')}</div>
                                                    </div>
                                                </div>
                                                <ul>
                                                    {dashboardData?.Subscribedetails?.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="text-infor-item item">
                                                                <div>
                                                                    <div className="title">{item?.trans_id}</div>
                                                                </div>
                                                                <div>
                                                                    <p>{item?.plan_title}</p>
                                                                </div>
                                                                <div>
                                                                    <p>${item?.amount}</p>
                                                                </div>
                                                                <div>
                                                                    <p>{item?.expire_date}</p>
                                                                </div>
                                                                <div>
                                                                    <p>{item?.p_name}</p>
                                                                </div>
                                                                <div>
                                                                    <div className="box-status">{item?.day}</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}

                                                </ul>
                                            </div>

                                        </div>
                                    </>
                                )}
                                {activeName === 'Wallet' && (
                                    <WalletList activeName={activeName} />
                                )}
                                {activeName === 'Chat' && (
                                    <>
                                        <div className='d-flex mob-dash flex-col pt-5 pb-5'>
                                            <div className='col-10'>
                                                <h3>{t('Message')}</h3>
                                                <div className="text-content">{t('we glade to see you again!')}</div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {activeName === 'Notification' && (
                                    <NotificationSec dashboardData={dashboardData}/>
                                )}
                                {activeName === 'Country' && (
                                    <>
                                        <div className='d-flex mob-dash flex-col pt-5 pb-5'>
                                            <div className='col-10'>
                                                <h3>{t('Country')}</h3>
                                                <div className="text-content">{t('we glade to see you again!')}</div>
                                            </div>
                                        </div>
                                        <div className="wg-box pl-44 pr-29 min_box_size" >
                                            <section>
                                                <div className="cl-container">
                                                    <div className="col-12">
                                                        <div className="wrap">
                                                            <div className='flat-cities style-4'>
                                                                <div className="row col-12">
                                                                    {dashboardData?.CountryData?.map(item => (
                                                                        <div key={item?.id} className={`col-12 pointer col-sm-6 pb-5 col-md-4 col-lg-3 col-xl-3 `} onClick={() => handleCountryClick(item?.id)}>
                                                                            <div className={`cities-item style-2 wow fadeInUp ${selectedCountryId === item?.id ? 'active-map' : ''}`} data-wow-delay="0.1s">
                                                                                <img src={`${imageBaseUrl}${item?.img}`} alt="" />
                                                                                <div className="content">
                                                                                    <h4>{item?.title}</h4>
                                                                                </div>
                                                                                <a></a>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </>
                                )}
                                {activeName == 'Invite Friends' && (
                                    <InviteFriendsSec dashboardData={dashboardData} />
                                )}

                            </div>

                            <div className={`btn-canvas ${isCanvasActive ? 'active' : ''}`} onClick={handleCanvasClick}>
                                <span></span>
                                <div className='text-content'>{t('Dashboard Navigation')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showCancelModal && <Cancel />}

            {showSnackbar && responseMsg && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={responseMsg} />}
        </>
    )
}

const FavoriteList = () => {
    const [favList, setFavList] = useState([]);
    const { isUserId, imageBaseUrl, selectedId, selectedCountryId, setProductDetailId } = useContextex();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userFavList = useSelector(getUserFavListData);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                await dispatch(fetchFavProdList({ uid: isUserId, property_type: selectedId, country_id: selectedCountryId }));
            } catch (error) {
                console.error("Error fetching favorite list:", error);
            }
        };

        fetchFavorites();
    }, [dispatch, isUserId, selectedId, selectedCountryId]);

    useEffect(() => {
        if (userFavList) {
            setFavList(userFavList?.propetylist || []);
        }
    }, [userFavList]);

    const renderFavoriteItem = (item) => (
        <li key={item.id}>
            <div className="my-properties-item item">
                <div>
                    <div className="property">
                        <div className="image">
                            <img className='h-100' src={`${imageBaseUrl}${item.image}`} alt={item.title} />
                        </div>
                        <div>
                            <div className="price">${item.price}</div>
                            <div className="title p-0 m-0">
                                <a>{item.title.substring(0, 15)}</a>
                            </div>
                            <div className="location">
                                <div className="icon">
                                    <i className="flaticon-location"></i>
                                </div>
                                <p>{item.city}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="box-status">{item.rate}</div>
                </div>
                <div>
                    <p>{item.buyorrent === 1 ? 'Buy' : 'Rent'}</p>
                </div>
                <div className="icon pointer">
                    {item.IS_FAVOURITE === 1 ? <IconHeartFilled style={iconStyle} /> : <IconHeart style={iconStyle} />}
                </div>
            </div>
        </li>
    );

    return (
        <>
            <div className='d-flex mob-dash flex-col pt-5 pb-5'>
                <div className='col-10'>
                    <h3>My Favorite</h3>
                    <div className="text-content">{t('Your Current Wallet Balance is')}</div>
                </div>
            </div>
            <div className="wg-box" style={{ height: '100vh' }}>
                <div className="table-text-infor default mb-40">
                    <div className="head">
                        <div className="item"><div className="text">{t('Listing Title')}</div></div>
                        <div className="item"><div className="text">{t('Rate')}</div></div>
                        <div className="item"><div className="text">{t('Sqrft')}</div></div>
                        <div className="item"><div className="text">{t('Action')}</div></div>
                    </div>
                    <ul>
                        {favList.length > 0 ? favList.map(renderFavoriteItem) : <p>{t('No favorites found')}.</p>}
                    </ul>
                </div>
            </div>
        </>
    );
};

export const WalletList = ({ activeName }) => {
    const [userWalletAmount, setUserWalletAmount] = useState(null)
    const [userWalletitem, setUserWalletItem] = useState(null);
    const [selectedPaymentId, setSelectedPaymentId] = useState('');
    const [paymentTrigger, setPaymentTrigger] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(null);
    const { t } = useTranslation();
    const { isUserId, imageBaseUrl, userCurrency, paymentGatwayList, setWalletAddAmount, transactionId, walletAddAmount } = useContextex();

    const dispatch = useDispatch()
    const walletReport = useSelector(getWalletReportData)

    useEffect(() => {
        if ((selectedPaymentId === 'Stripe' && paymentTrigger) || (selectedPaymentId === 'Paypal' && paymentTrigger)) {
            setIsOpenModal(true)
        }
    }, [selectedPaymentId, paymentTrigger])

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchWalletData({ uid: isUserId }));
            } catch (error) {
                console.error("Error fetching wallet data:", error);
            }
        };

        fetchData();
    }, [dispatch, isUserId, transactionId]);

    useEffect(() => {
        if (walletReport) {
            // setWalletData(walletReport);
            setUserWalletItem(walletReport?.Walletitem);
            setUserWalletAmount(walletReport?.wallet)
        }
    }, [walletReport, userWalletAmount, userWalletitem]);

    const closeModal = () => setIsOpenModal(false);

    const product_name = "Subscribe Payment";
    const product_amount = walletAddAmount;
    const product_id = 1

    return (
        <>
            <div className='d-flex mob-dash flex-col pt-5 pb-5'>
                <div className='col-10'>
                    <h3>{t('My Payout')}</h3>
                    <div className="text-content">{t('Your Current Wallet Balance is')} <span style={{ color: "red" }}>{userCurrency}{userWalletAmount}</span></div>
                </div>
            </div>
            <div className="grid-section-1">
                <div className="wg-box pl-44" style={{ height: '100vh' }}>
                    <div className="container col-12 h-100">

                        <div className="col-sm-12  w-100 col-md-6 position-relative ">
                            <div className='d-flex my-3 align-items-center '>
                                <div className='col-sm-12 col-md-12 col-xl-9 col-lg-9'>
                                    <h6>{t('Add Amount')}</h6>
                                </div>
                            </div>
                            <form>
                                <fieldset className="name">
                                    <input type="text" className='w-100 ' onChange={(e) => setWalletAddAmount(e.target.value)} id="galleryCategoryName" name="prop_title" placeholder="Add Amount" required="required" />
                                </fieldset>
                            </form>
                        </div>

                        <div className="col-sm-12  w-100 col-md-6 position-relative ">
                            <div className='d-flex my-3 align-items-center '>
                                <div className='col-sm-12 col-md-12 col-xl-9 col-lg-9'>
                                    <h6>{t('Select Payment Method')}</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 h-100" >
                            <div className="wrap" style={{ overflowY: 'auto', height: '70%' }}>
                                <div className="row gap-4 col-12 px-3 d-flex justify-content-between" >
                                    {paymentGatwayList?.map((item) => (
                                        <div key={item?.id} className={`categories-item col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 wow fadeInUp ${selectedPaymentId === item?.id ? 'active-map' : ''}`} onClick={() => setSelectedPaymentId(item?.title)}>
                                            <div className="icon">
                                                <img src={`${imageBaseUrl}${item?.img}`} alt={`${item?.title} icon`} />
                                            </div>
                                            <div>
                                                <div className="name">
                                                    <a>{item?.title}</a>
                                                </div>
                                                <div className="text">{item?.subtitle}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="wg-box" style={{ height: '100vh' }}>
                    <h4>{t('Recent Transactions')}</h4>
                    <ul className="wrap-recent-activities" style={{ overflowY: 'auto', height: '90%' }}>
                        {userWalletitem?.map((item, index) => (
                            <li className="recent-activities-item d-flex justify-content-between" key={index} >
                                <div ><IconWallet /></div>
                                <div>
                                    <p>{item?.tdate}</p>
                                    <p>{item?.status}</p>
                                </div>
                                <p className={item?.status === 'Credit' ? 'text-primary' : 'text-warning'}>{userCurrency}{item?.amt}{item?.status === 'Credit' ? '+' : '-'}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="button-submit mt-10">
                <button className="tf-button-primary" onClick={() => setPaymentTrigger(true)}>{t('Continue')}<i className="icon-arrow-right-add"></i></button>
            </div>

            {selectedPaymentId === 'Khalti Payment' && paymentTrigger && <KhaltiPayment product_name={product_name} product_amount={product_amount} />}
            {selectedPaymentId === 'FlutterWave' && paymentTrigger && <FlutterwavePayment product_name={product_name} booked_for={'wallet'} product_amount={product_amount} product_id={product_id} />}
            {selectedPaymentId === 'PayStack' && paymentTrigger && <PaystackPayment product_amount={product_amount} booked_for={'wallet'} />}
            {selectedPaymentId === 'Razorpay' && paymentTrigger && <RazorpayPayment product_amount={product_amount} booked_for={'wallet'} />}

            {isOpenModal && selectedPaymentId === 'Stripe' && paymentTrigger &&
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className="image-left">
                        <img src="./../assets/images/section/login.jpg" alt="" />
                        <h3>{t('Welcome to Your Real Estate Website')}</h3>
                    </div>
                    <div className="content-right p-5 ">
                        <form className='form-bacsic-infomation flex  flex-column'>
                            <StripePayment />
                        </form>
                    </div>
                </ReModal>
            }

            {isOpenModal && selectedPaymentId === 'Paypal' && paymentTrigger &&
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className="image-left">
                        <img src="./../assets/images/section/login.jpg" alt="" />
                        <h3>{t('Welcome to Your Real Estate Website')}</h3>
                    </div>
                    <div className="content-right p-5 ">
                        <form className='form-bacsic-infomation flex align-items-center justify-content-center d-flex flex-column'>
                            <PaypalPayment product_amount={product_amount} />
                        </form>
                    </div>
                </ReModal>
            }
            {activeName === 'Wallet' && <PaymentList />}
            {selectedPaymentId === 'Paytm' && paymentTrigger && <PaytmPayment product_amount={product_amount} />}
            {selectedPaymentId === 'SenangPay' && paymentTrigger && <SenangpayPayment product_amount={product_amount} />}
            {selectedPaymentId === 'Midtrans' && paymentTrigger && <MidtransPayment product_amount={product_amount} />}
            {selectedPaymentId === '2checkout' && paymentTrigger && <TwoCheckoutPayment product_amount={product_amount} />}
            {selectedPaymentId === 'Payfast' && paymentTrigger && <PayfastPayment product_name={product_name} product_amount={product_amount} booked_for={'wallet'} />}
            {selectedPaymentId === 'MercadoPago' && paymentTrigger && <MercadoPagoPayment product_name={product_name} product_amount={product_amount} />}
        </>
    )
}

export const UserProfileSec = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [updatedUserName, setUpdatedUserName] = useState('');
    const [updatedUserPassword, setUpdatedUserPassword] = useState('');
    const [responseMsg, setResponseMsg] = useState(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const proEdit = useSelector(getProfileEditData);

    const {
        isUserId,
        imageBaseUrl,
        loginUserData,
        setLoginUserData
    } = useContextex();

    const handleUpdateProfile = async (event) => {
        event.preventDefault();

        const formData = {
            name: updatedUserName || loginUserData?.UserLogin?.name,
            uid: isUserId,
            password: updatedUserPassword || loginUserData?.UserLogin?.password
        };

        try {
            await dispatch(fetchEditProUser(formData));
            setShowSnackbar(true);
            setResponseMsg(proEdit?.ResponseMsg);
            if (proEdit?.UserLogin) {
                const updatedUser = proEdit;
                setLoginUserData(updatedUser);
                localStorage.setItem('loginUser', JSON.stringify(updatedUser));
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    };
    
    const handleImageUpload = async () => {
        if (!selectedFile) {
            console.error('No file selected.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('avatar', selectedFile);

            const response = await axios.post(`${baseUrl}pro_image.php`, {
                 uid: isUserId,
                 img:selectedFile 
            });
            setShowSnackbar(true);
            if(response?.data?.ResponseCode == 200) {
                localStorage.setItem('loginUser', JSON.stringify(response?.data));
            }
            setResponseMsg(response?.data?.ResponseMsg);
            setSelectedFile(null);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setSelectedFile(reader.result.split(',')[1]);
            reader.readAsDataURL(file);
        }
    };

    const renderProfileImage = () => {
        const userAvatar = loginUserData?.UserLogin?.pro_pic;
        const imageSrc = selectedFile || (userAvatar ? `${imageBaseUrl}${userAvatar}` : './../assets/icon/profile-default.png');
        return <img className='h-100 w-100' src={imageSrc} alt="Avatar" />;
    };

    return (
        <>
            <div className='d-flex flex-col mob-dash pt-5 pb-5'>
                <div className='col-10'>
                    <h3>{t('Profile')}</h3>
                    <div className="text-content">{t('Your Current Wallet Balance is')}</div>
                </div>
            </div>

            <div className="wg-box pl-44 mb-20" style={{ height: '100vh' }}>
                <h4>{t('Profile Information')}</h4>
                <div className="my-profiles-wrap">
                    <div className="avatar-image">
                        <div className="left">
                            {renderProfileImage()}
                        </div>
                        <div className="right">
                            <label className="uploadfile">
                                <input
                                    type={selectedFile ? 'text' : 'file'}
                                    className='pointer'
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                <div
                                    className="tf-button-primary d-flex align-items-center style-bg-white"
                                    onClick={selectedFile ? handleImageUpload : undefined}
                                >
                                    {selectedFile ? 'Update Profile' : 'Edit Image'}
                                    <i className="flaticon-upload-1"></i>
                                </div>
                                <p className="file-name">
                                    {t('Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png')}
                                </p>
                            </label>
                        </div>
                    </div>

                    <form className="form-profiles flex gap30 flex-column" onSubmit={handleUpdateProfile}>
                        <div className="cols">
                            <fieldset className="name has-top-title">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    required
                                    onChange={(e) => setUpdatedUserName(e.target.value)}
                                    value={updatedUserName || loginUserData?.UserLogin?.name}
                                />
                                <label htmlFor="">{t('Username')}</label>
                            </fieldset>
                            <fieldset className="password has-top-title">
                                <input
                                    type="text"
                                    placeholder="Update Password"
                                    required
                                    onChange={(e) => setUpdatedUserPassword(e.target.value)}
                                    value={updatedUserPassword || loginUserData?.UserLogin?.password}
                                />
                                <label htmlFor="">{t('Old Password')}</label>
                            </fieldset>
                        </div>

                        <div className="cols">
                            <fieldset className="email has-top-title">
                                <input
                                    disabled
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={loginUserData?.UserLogin?.email}
                                    required
                                />
                                <label htmlFor="">{t('Email')}</label>
                            </fieldset>
                            <fieldset className="phone has-top-title">
                                <input
                                    disabled
                                    type="tel"
                                    placeholder="Phone"
                                    name="number"
                                    value={loginUserData?.UserLogin?.mobile}
                                    required
                                />
                                <label htmlFor="">{t('Number')}</label>
                            </fieldset>
                        </div>
                        <div className="button-submit mt-10">
                            <button className="tf-button-primary" type="submit">
                                {t('Save Profile')}<i className="icon-arrow-right-add"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export const UserBookingSec = ({ activeName }) => {
    const [showReceiptTab, setShowReceiptTab] = useState(false);
    const [activeTab, setActiveTab] = useState('active');
    const [bookingData, setBookingData] = useState([]);
    const [droppedReview, setDroppedReview] = useState(false);
    const { t } = useTranslation();
    const { isUserId, imageBaseUrl, setBookingId, setShowCancelModal, showCancelModal } = useContextex();
    const dispatch = useDispatch();
    const secoundData = useSelector(getSecoundData);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchSecoundData('u_book_status_wise.php', { uid: isUserId, status: activeTab }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [dispatch, isUserId, activeTab]);

    useEffect(() => {
        if (secoundData?.statuswise) {
            setBookingData(secoundData.statuswise);
        }
    }, [secoundData]);

    useEffect(() => {
        if (activeName === 'My Booking' && activeTab === 'completed') {
            setDroppedReview(true);
        } else {
            setDroppedReview(false);
        }
    }, [activeName, activeTab]);

    const navigate = useNavigate();

    const handleBackButton = () => {
        navigate(-1, { fallback: '/fallback' });
        setActiveTab('active');
    };

    const handleReceiptClick = (bookId) => {
        setBookingId(bookId);
        setShowReceiptTab(true);
    };

    const handleCancelClick = () => {
        setShowCancelModal(!showCancelModal);
    };

    const renderBackButton = () => (
        <div onClick={handleBackButton}>
            <Link to='/dashboard'>
                <a className="tf-button-primary style-black active" onClick={() => setShowReceiptTab(false)}>
                    {t('Back')} <IconArrowBackUp />
                </a>
            </Link>
        </div>
    );

    const renderBookingItem = (item, index) => (
        <li key={index}>
            <div className="my-properties-item item">
                <div>
                    <div className="property">
                        <div className="image booking-image">
                            <img src={`${imageBaseUrl}${item.prop_img}`} alt={item.prop_title} />
                        </div>
                        <div>
                            <div className="price">${item.prop_price}</div>
                            <div className="title">
                                <a>{item.prop_title}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p>{item.total_day} {t('Days')}</p>
                </div>
                <div>
                    <div className="box-status">{item.book_status}</div>
                </div>
                <div>
                    <p>{item.p_method_id === '2' ? 'Unpaid' : 'Paid'}</p>
                </div>
                <div>
                    <ul className="wg-icon" style={{ gap: '0' }}>
                        {activeTab === 'active' && (
                            <>
                                <a className="tf-button-primary style-black active mx-1" style={{ gap: '0' }} onClick={handleCancelClick}>
                                    {t('Cancel')}
                                </a>
                                <a className="tf-button-primary active" style={{ gap: '0' }} onClick={() => handleReceiptClick(item.book_id)}>
                                    {t('Receipt')}
                                </a>
                            </>
                        )}
                        {activeTab === 'completed' && (
                            <a className="tf-button-primary active" style={{ gap: '0' }} onClick={() => handleReceiptClick(item.book_id)}>
                                {t('Receipt')}
                            </a>
                        )}
                    </ul>
                </div>
            </div>
        </li>
    );

    return (
        <>
            <div className='d-flex flex-col col-12 mob-dash pt-5 pb-5'>
                <div className='col-sm-9 col-6 col-xs-9 col-md-8 col-lg-10 '>
                    <h3>{t('My Booking')}</h3>
                    <div className="text-content">{t('We are glad to see you again')}!</div>
                </div>

                {showReceiptTab && activeName === 'My Booking' && renderBackButton()}
            </div>

            <div className="wg-box pl-44 pr-29" style={{ minHeight: '100vh' }}>
                {showReceiptTab && activeName === 'My Booking' ? (
                    <Receipt droppedReview={droppedReview} setDroppedReview={setDroppedReview} />
                ) : (
                    <div className="table-listing-properties mb-40">
                        <div className="widget-tabs style-2">
                            <ul className="widget-menu-tab">
                                <li className={`item-title ${activeTab === 'active' ? 'active' : ''}`} onClick={() => setActiveTab('active')}>
                                    <span className="inner">{t('Active')}</span>
                                </li>
                                <li className={`item-title ${activeTab === 'completed' ? 'active' : ''}`} onClick={() => setActiveTab('completed')}>
                                    <span className="inner">{t('Completed')}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="head">
                            <div className="item"><div className="text">{t('Listing Title')}</div></div>
                            <div className="item"><div className="text">{t('Total Days')}</div></div>
                            <div className="item"><div className="text">{t('Status')}</div></div>
                            <div className="item"><div className="text">{t('P_Status')}</div></div>
                            <div className="item"><div className="text">{t('Action')}</div></div>
                        </div>
                        <ul>
                            {bookingData.map(renderBookingItem)}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};

export const InviteFriendsSec = ({ dashboardData }) => {
    const { userCurrency } = useContextex();
    const { t } = useTranslation();
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard:', text);
            })
            .catch(err => {
                console.error('Failed to copy text!', err);
            });
    };

    return (
        <>
            <div className='d-flex mob-dash flex-col pt-5 pb-5'>
                <div className='col-10'>
                    <h3>{t('Invite Friends')}</h3>
                    <div className="text-content">{t('we glade to see you again!')}</div>
                </div>
            </div>
            <div className="wg-box pl-44 pr-29" style={{ minHeight: '100vh' }}>
                <section className="tf-section mt-21 work-with-us style-2 pt-0">
                    <div className="cl-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading-section text-center">
                                    <h2 className="wow fadeInUp">{t('Earn')} {userCurrency}{dashboardData?.refercredit} {t('for')} </h2>
                                    <h2 className="text wow fadeInUp"> {t('Each Friend you refer')}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-center ">
                            <div className="col-xl-10 mb-50">
                                <div className="wrap ">
                                    <div className="box-icon style-1 wow fadeInUp">
                                        <div className="icon has-ellipse">
                                            <i className="flaticon-house-1"></i>
                                        </div>
                                        <div className="content">
                                            <a  className="title">{t('Share referral link')}</a>
                                            <p>{t('Share the referral link')} <br /> {t('with your friends')}.</p>
                                        </div>
                                    </div>
                                    <div className="box-icon style-1 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="icon has-ellipse">
                                            <i className="flaticon-home"></i>
                                        </div>
                                        <div className="content">
                                            <a  className="title">{t(`Friend's Referral Bonus`)}</a>
                                            <p>{t('Friends get')} {userCurrency}{dashboardData?.refercredit} {t('on their  first')}<br /> {t('complate transaction')}.</p>
                                        </div>
                                    </div>
                                    <div className="box-icon style-1 wow fadeInUp" data-wow-delay="0.2s">
                                        <div className="icon has-ellipse">
                                            <i className="flaticon-shield"></i>
                                        </div>
                                        <div className="content">
                                            <a  className="title">{t('You Got')}</a>
                                            <p>{t('You get')} {userCurrency}{dashboardData?.signupcredit} {t('on your wallet')} .</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <form className='justify-center row w-50 mt-21'>
                                <div className="bottom flex align-items-center w-100 justify-content-center">
                                    <div className="flex gap20 flex-grow flex-row w-100">
                                        <div className="input-search relative w-100">
                                            <fieldset className="name">
                                                <input
                                                    type="text"
                                                    disabled
                                                    placeholder={dashboardData?.code || ''}
                                                    className=""
                                                    name="name"
                                                    tabIndex="2"
                                                    value={dashboardData?.code || ''}
                                                    aria-required="true"
                                                    required
                                                />
                                            </fieldset>
                                            <div className="button-submit style-absolute-right-center">
                                                <button
                                                    className="style-icon-default"
                                                    type="button"
                                                    onClick={() => handleCopy(dashboardData?.code || '')}
                                                >
                                                    <IconCopy />
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export const NotificationSec = ({ dashboardData }) => {
    const { t } = useTranslation();
    return(
        <>
            <div className='d-flex mob-dash flex-col pt-5 pb-5'>
                <div className='col-10'>
                    <h3>{t('Notification')}</h3>
                    <p className="text-content">{t('We’re glad to see you again')}!</p>
                </div>
            </div>

            <div className="wg-box pl-44 pr-29">
                <div className="table-text-infor default mb-40">
                    <div className="head d-flex justify-content-between">
                        <div className="item">
                            <div className="text">{t('Title')}</div>
                        </div>
                        <div className="item" >
                            <div className="text float-end">{t('Date&Time')}</div>
                        </div>
                    </div>

                    <ul>
                        {dashboardData?.NotificationData?.map((item, index) => (
                            <li key={index}>
                                <div className="text-infor-item ">
                                    <div className="item">
                                        <div className="title">{item?.title}</div>
                                        <p>{item?.description}</p>
                                    </div>
                                    <div className="item">
                                        <p className=' float-end'>{item?.datetime}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

        </>
    )
}