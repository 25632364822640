import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import i18n from '../LanguageConfig';

function Footer() {
    const [language, setLanguage] = useState("en");
    const navigate = useNavigate()
    const currentYear = new Date().getFullYear()
    const { t } = useTranslation();
    const handleHome = () => {
        navigate('/')
    }

    const handleLanguage = (event) => {
        const selectedlang = event.target.dataset.value
        setLanguage(selectedlang);
        i18n.changeLanguage(selectedlang)
    };

    return (
        <>
            <footer className="footer">
                <div className="footer-inner">
                    <div className="footer-inner-wrap">

                        <div className="center-footer">
                            <div className="footer-cl-1">
                                <div className="ft-title">
                                    <div className="logo-footer">
                                        <a>
                                            <img id="logo-footer" className='pointer w-50 h-50 ' onClick={handleHome} src="./assets/icon/logo-text.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="text">{t('Subscribe to our newsletter to receive our weekly feed')}.</div>
                                <form className="form-subscribe style-line-bottom">
                                </form>
                                <div className="wg-social justify-content-start">
                                    <span>{t('Follow Us')}</span>
                                    <ul className="list-social">
                                        <li>
                                            <a>
                                                <i className="icon-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <i className="icon-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a >
                                                <i className="icon-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a >
                                                <i className="icon-linkedin2"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-cl-3">
                                <div className="ft-title">{t('Quick Links')}</div>
                                <ul className="navigation-menu-footer">

                                    <li className='pointer' onClick={() => navigate('/contact_us')} ><a >{t('Contact')}</a></li>
                                    <li className='pointer' onClick={() => navigate('/faq_list')}><a >{t('Faq')}</a></li>
                                    <li className='pointer' onClick={() => navigate('/privacy_policy')} ><a >{t('Privacy Policy')}</a></li>
                                    <li className='pointer' onClick={() => navigate('/terms_and_condition')} ><a >{t('Terms')} &amp; {t('Conditions')}</a></li>
                                </ul>
                            </div>
                            <div className="footer-cl-4">
                                <div className="ft-title">{t('Contact Us')}</div>
                                <ul className="navigation-menu-footer">
                                    <li><div className="text">hi@justhome.com (123) 456-7890</div></li>
                                </ul>
                            </div>
                            <div className="footer-cl-5">
                                <div className="ft-title">{t('Our Address')}</div>
                                <ul className="navigation-menu-footer">
                                    <li><div className="text">90 Fifth Avenue, 3rd Floor San Francisco, CA 1980</div></li>
                                </ul>
                            </div>
                            <div className="footer-cl-6">
                                <div className="ft-title">{t('Get the app')}</div>
                                <ul className="ft-download">
                                    <li>
                                        <a >
                                            <div className="icon">
                                                <i className="icon-appleinc"></i>
                                            </div>
                                            <div className="app">
                                                <div>{t('Download on the')}</div>
                                                <div>{t('Apple Store')}</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a >
                                            <div className="icon">
                                                <i className="icon-ch-play"></i>
                                            </div>
                                            <div className="app">
                                                <div>{t('Get in on')}</div>
                                                <div>{t('Google Play')}</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="nice-select" tabindex="0">
                                            <span class="current" style={{color:'white'}}>Select Language</span>
                                            <ul class="list w-100" style={{height:'150px'}}>
                                                <li data-value="en" onClick={handleLanguage} class={`option w-100 ${language === 'en' ? 'selected' : ''}`}>English</li>
                                                <li data-value="ar" onClick={handleLanguage} class={`option w-100 ${language === 'ar' ? 'selected' : ''}`}>Arabic</li>
                                                <li data-value="hi" onClick={handleLanguage} class={`option w-100 ${language === 'hi' ? 'selected' : ''}`}>Hindi</li>
                                                <li data-value="sp" onClick={handleLanguage} class={`option w-100 ${language === 'sp' ? 'selected' : ''}`}>Spanish</li>
                                                <li data-value="fr" onClick={handleLanguage} class={`option w-100 ${language === 'fr' ? 'selected' : ''}`}>France</li>
                                                <li data-value="gr" onClick={handleLanguage} class={`option w-100 ${language === 'gr' ? 'selected' : ''}`}>Germay</li>
                                                <li data-value="in" onClick={handleLanguage} class={`option w-100 ${language === 'in' ? 'selected' : ''}`}>Indonesia</li>
                                                <li data-value="sa" onClick={handleLanguage} class={`option w-100 ${language === 'sa' ? 'selected' : ''}`}>South Africa</li>
                                                <li data-value="tu" onClick={handleLanguage} class={`option w-100 ${language === 'tu' ? 'selected' : ''}`}>Turkish</li>
                                                <li data-value="pu" onClick={handleLanguage} class={`option w-100 ${language === 'pu' ? 'selected' : ''}`}>Portuguese</li>
                                            </ul>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bottom-footer">
                            <div className="text">{t('Copyright')} © {currentYear}. {t('GoProperty')}</div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
