import React, { useEffect, useState } from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const handleBlur = () => {
    console.log("[blur]");
};

const handleChange = (change) => {
    console.log("[change]", change);
};

const handleClick = () => {
    console.log("[click]");
};

const handleFocus = () => {
    console.log("[focus]");
};

const handleReady = () => {
    console.log("[ready]");
};

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize,
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                },
                padding
            },
            invalid: {
                color: "#9e2146"
            }
        }
    };
};

const SplitForm = ({ fontSize }) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
        });

        console.log("[PaymentMethod]", payload);
        // Handle the payment method payload (e.g., send to backend for processing)
    };

    return (
        <form onSubmit={handleSubmit} className="DemoWrapper d-flex flex-column">
            <label>
                Card number
                <CardNumberElement
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onReady={handleReady}
                    options={createOptions(fontSize)}
                />
            </label>
            <label>
                Expiration date
                <CardExpiryElement
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onReady={handleReady}
                    options={createOptions(fontSize)}
                />
            </label>
            <label>
                CVC
                <CardCvcElement
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onReady={handleReady}
                    options={createOptions(fontSize)}
                />
            </label>
            <button type="submit">Pay</button>
        </form>
    );
};

const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

export const StripePayment = () => {
    const [elementFontSize, setElementFontSize] = useState(() =>
        window.innerWidth < 450 ? "14px" : "18px"
    );

    useEffect(() => {
        const handleResize = () => {
            setElementFontSize(window.innerWidth < 450 ? "14px" : "18px");
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="Checkout">
            <Elements stripe={stripePromise}>
                <SplitForm fontSize={elementFontSize} />
            </Elements>
        </div>
    );
};
