import React, { useEffect } from 'react';
import { Modal } from 'bootstrap'; 

export const ReModal = ({ isOpenModal, onClose, children }) => {
  useEffect(() => {
    const modalElement = document.getElementById('modallogin');
    const modal = new Modal(modalElement);
    modal.show();
  }, [isOpenModal]); 

  return (
    <div className="modal fade modalCenter" id="modallogin">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-sm">
          <a className="btn-hide-modal pointer" data-bs-dismiss="modal" onClick={onClose}><i className="pointer icon-close"></i></a>
            {children}
        </div>
      </div>
    </div>
  );
};
