import axios from 'axios';
import React, { useEffect } from 'react'

export const MercadoPagoPayment = ({ product_amount, product_name }) => {
    const publicKey = 'TEST-47dbadaa-3722-4781-907b-e2e6d3548b54'; //  MercadoPago Public Key
    const description = 'this is devo demo'
        useEffect(() => {
            const initializePayment = async () => {
                try {
                    const payload = {
                        items: [
                            {
                                id: 'item_id',
                                title: product_name,
                                description: description,
                                quantity: 1,
                                currency_id: 'USD', 
                                unit_price: product_amount, 
                            }
                        ],
                        payer: {
                            email: 'customer@example.com',
                        },
                        back_urls: {
                            success: 'http://localhost:3000/dashboard',
                            pending: 'http://localhost:3000/dashboard',
                            failure: 'http://localhost:3000/dashboard',
                        },
                        auto_return: 'approved',
                    };
                    const response = await axios.post('https://api.mercadopago.com/checkout/preferences', payload, {
                        headers: {
                            Authorization: `Bearer ${publicKey}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    window.location.href = response.data.init_point;
                } catch (error) {
                    console.error('Error initiating payment:', error);
                }
            };

            initializePayment();
        }, [product_amount, description]);

    return (
        <div>
            <p>Initiating payment...</p>
        </div>
    );
}
