import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddToProperty, fetchData } from '../store/action';
import { IconLocation } from '@tabler/icons-react';
import { useContextex } from '../context/useContext';
import { useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getAddPropertyData, getData } from '../store/selector';

const AddPropartyForm = () => {
    const { t } = useTranslation();
    const {
        propertyType,
        countryData,
        imageBaseUrl,
        isUserId,
        isEditSelectedProperty,
        editSelectedProperty,
        userCurrency
    } = useContextex();

    const [selectedProperty, setSelectedProperty] = useState(() => isEditSelectedProperty ? (editSelectedProperty?.buyorrent === 2 ? 'Buy' : 'Rent') : '');
    const [selectedCountry, setSelectedCountry] = useState(() => isEditSelectedProperty ? editSelectedProperty?.country_id : '');
    const [selectedPropertyType, setSelectedPropertyType] = useState(isEditSelectedProperty ? editSelectedProperty?.property_type : '');
    const [selectedPropertyStatus, setSelectedPropertyStatus] = useState(isEditSelectedProperty ? editSelectedProperty?.status : '');
    const [checkedFacilities, setCheckedFacilities] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [selectedFile, setSelectedFile] = useState(isEditSelectedProperty ? `${imageBaseUrl}${editSelectedProperty?.image}` : null);
    const [propertyDetails, setPropertyDetails] = useState({
        prop_title: isEditSelectedProperty ? editSelectedProperty?.title : '',
        prop_address: isEditSelectedProperty ? editSelectedProperty?.address : '',
        mobile_number: isEditSelectedProperty ? editSelectedProperty?.mobile : '',
        prop_price: isEditSelectedProperty ? editSelectedProperty?.price : '',
        prop_description: isEditSelectedProperty ? editSelectedProperty?.description : '',
        prop_no_of_beds: isEditSelectedProperty ? editSelectedProperty?.beds : '',
        prop_no_of_bath: isEditSelectedProperty ? editSelectedProperty?.bathroom : '',
        prop_sqrt: isEditSelectedProperty ? editSelectedProperty?.sqrft : '',
        prop_rating: isEditSelectedProperty ? editSelectedProperty?.rate : '',
        prop_capacity: isEditSelectedProperty ? editSelectedProperty?.plimit : '',
        prop_city: isEditSelectedProperty ? editSelectedProperty?.city : '',
    });
    const [position, setPosition] = useState({
        latitude: isEditSelectedProperty ? editSelectedProperty?.latitude : null,
        longitude: isEditSelectedProperty ? editSelectedProperty?.longtitude : null
    });
    const [propertyFacilityData, setPropertyFacilityData] = useState([]);
    const [, setDefaultSelectedCountry] = useState('');
    const [, setDefaultSelectedPropertyType] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const data = useSelector(getData)
    const sendAddProparty = useSelector(getAddPropertyData)

    useEffect(() => {
        if (isEditSelectedProperty && editSelectedProperty) {
            setSelectedCountry(editSelectedProperty?.country_id);
            setDefaultSelectedCountry(editSelectedProperty?.country_title);
            setDefaultSelectedPropertyType(editSelectedProperty?.property_type);
            setSelectedPropertyType(editSelectedProperty?.property_type_id);
        } else {
            setSelectedCountry('');
            setDefaultSelectedCountry('');
            setDefaultSelectedPropertyType('');
            setSelectedPropertyType('');
        }
    }, [isEditSelectedProperty, editSelectedProperty]);

    useEffect(() => {
        const fetchFacilityData = async () => {
            try {
                const response = await dispatch(fetchData('u_facility.php'));
                // Ensure the response has the data you expect
                // const data = response?.payload; // Assuming the response has a payload property
                if (data?.facilitylist) {
                    setPropertyFacilityData(data.facilitylist);
                }
            } catch (error) {
                console.error("Error fetching facility data:", error);
            }
        };

        fetchFacilityData();
    }, [dispatch]);

    const handleAddProperty = async (event) => {
        event.preventDefault();
        const endpoint = isEditSelectedProperty ? 'u_property_edit.php' : 'u_property_add.php';
        const apiData = {
            uid: isUserId,
            status: selectedPropertyStatus,
            plimit: propertyDetails.prop_capacity,
            country_id: selectedCountry,
            pbuysell: selectedProperty,
            title: propertyDetails.prop_title,
            address: propertyDetails.prop_address,
            description: propertyDetails.prop_description,
            ccount: propertyDetails.prop_city,
            facility: checkedFacilities.join(','),
            ptype: selectedPropertyType,
            beds: propertyDetails.prop_no_of_beds,
            bathroom: propertyDetails.prop_no_of_bath,
            sqft: propertyDetails.prop_sqrt,
            rate: propertyDetails.prop_rating,
            latitude: position.latitude,
            longitude: position.longitude,
            mobile: propertyDetails.mobile_number,
            price: propertyDetails.prop_price,
            img: selectedFile,
            prop_id: editSelectedProperty?.id
        };

        try {
            const response = await dispatch(fetchData(endpoint, apiData));
            setShowSnackbar(true);
            console.log(response)
            if (response && sendAddProparty) {
                navigate('/addProparty');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleGetLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPropertyDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedFile(reader.result.split(',')[1]);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelect = (setter) => (event) => {
        setter(event.target.getAttribute('data-value'));
    };

    const handleCheckBox = (title) => {
        setCheckedFacilities(prev => {
            const isChecked = prev.includes(title);
            return isChecked ? prev.filter(item => item !== title) : [...prev, title];
        });
    };

    const handleCloseSnackbar = () => setShowSnackbar(false);
    const snackMessage = data?.ResponseMsg;

    return (
        <>
            <div className="wg-box pl-44 mb-20">
                <form className="form-basic-information flex gap30 flex-column">
                    <div className='cols'>
                        <SelectField
                            label="Property Type"
                            options={[
                                { value: '', label: 'Select Property' },
                                { value: 'Buy', label: 'Buy' },
                                { value: 'Rent', label: 'Rent' }
                            ]}
                            selectedValue={selectedProperty}
                            onSelect={handleSelect(setSelectedProperty)}
                        />
                        <SelectField
                            label="Country"
                            options={countryData?.map(item => ({ value: item?.id, label: item?.title }))}
                            selectedValue={selectedCountry}
                            onSelect={handleSelect(setSelectedCountry)}
                        />
                    </div>
                    <TextField
                        label="Property Title"
                        name="prop_title"
                        value={propertyDetails.prop_title}
                        onChange={handleChange}
                    />
                    <TextAreaField
                        label="Property Description"
                        name="prop_description"
                        value={propertyDetails.prop_description}
                        onChange={handleChange}
                    />
                </form>
            </div>

            <div className="wg-box pl-44 mb-20">
                <h4>{t('Additional')}</h4>
                <form className="form-additional flex gap30 flex-column">
                    <SelectField
                        label="Property Type"
                        options={propertyType?.map(item => ({ value: item.id, label: item.title }))}
                        selectedValue={selectedPropertyType}
                        onSelect={handleSelect(setSelectedPropertyType)}
                    />
                    <div className="cols cols-two">
                        <TextField
                            label="Address"
                            name="prop_address"
                            value={propertyDetails.prop_address}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Mobile Number"
                            name="mobile_number"
                            value={propertyDetails.mobile_number}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Property Price"
                            name="prop_price"
                            value={propertyDetails.prop_price}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="cols cols-two">
                        <TextField
                            label="Total Beds"
                            name="prop_no_of_beds"
                            value={propertyDetails.prop_no_of_beds}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Total Bathrooms"
                            name="prop_no_of_bath"
                            value={propertyDetails.prop_no_of_bath}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Property Sqft"
                            name="prop_sqrt"
                            value={propertyDetails.prop_sqrt}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Property Rating"
                            name="prop_rating"
                            value={propertyDetails.prop_rating}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="cols cols-two">
                        <TextField
                            label="Capacity"
                            name="prop_capacity"
                            value={propertyDetails.prop_capacity}
                            onChange={handleChange}
                        />
                        <TextField
                            label="City, Country"
                            name="prop_city"
                            value={propertyDetails.prop_city}
                            onChange={handleChange}
                        />
                    </div>
                </form>
            </div>

            <div className="wg-box pl-44 mb-20">
                <h4>{t('Select Property Facility')}</h4>
                <form className="form-amenities">
                    <ul className="grid-checkbox">
                        {propertyFacilityData?.map(item => (
                            <li className="checkbox-item" key={item?.id}>
                                <label>
                                    <p>{item?.title}</p>
                                    <input
                                        type="checkbox"
                                        onChange={() => handleCheckBox(item?.id)}
                                        checked={
                                            isEditSelectedProperty
                                                ? editSelectedProperty?.facility_select?.includes(item?.id)
                                                : checkedFacilities.includes(item?.id)
                                        }
                                    />
                                    <span className="btn-checkbox"></span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </form>
            </div>

            <div className="wg-box pl-44 mb-20">
                <h4>{t('Status')}</h4>
                <form className="form-price flex gap30 flex-column">
                    <div className="cols">
                        <TextField
                            label="Latitude"
                            value={position.latitude}
                            disabled
                        />
                        <TextField
                            label="Longitude"
                            value={position.longitude}
                            disabled
                        />
                    </div>

                    <div className='d-flex p-0 m-0 pointer' onClick={handleGetLocation}>
                        <IconLocation className='float-start p-1 bg-primary m-2 rounded-circle text-white' />
                        <h6 className='p-2'>{t('Click for Current Location')}</h6>
                    </div>

                    <div className="cols">
                        <FileUpload
                            selectedFile={selectedFile}
                            onFileChange={handleImageChange}
                        />
                        <SelectField
                            label="Property Status"
                            options={[
                                { value: '0', label: 'Publish' },
                                { value: '1', label: 'UnPublish' }
                            ]}
                            selectedValue={selectedPropertyStatus}
                            onSelect={handleSelect(setSelectedPropertyStatus)}
                        />
                    </div>

                    <div className="button-submit mt-10">
                        <button className="tf-button-primary" onClick={handleAddProperty}>
                            {t('Add Property')} <i className="icon-arrow-right-add"></i>
                        </button>
                    </div>
                </form>
            </div>

            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMessage} />}
        </>
    );
};

const SelectField = ({ label, options, selectedValue, onSelect }) => (
    <div className="nice-select" tabIndex="0">
        <span className="current">{selectedValue ? options.find(option => option.value === selectedValue)?.label : label}</span>
        <ul className="list">
            {options?.map(option => (
                <li
                    key={option.value}
                    data-value={option.value}
                    className={selectedValue === option.value ? 'option selected' : 'option'}
                    onClick={onSelect}
                >
                    {option.label}
                </li>
            ))}
        </ul>
    </div>
);

const TextField = ({ label, name, value, onChange, disabled }) => (
    <fieldset className="text">
        <input
            type="text"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={label}
            disabled={disabled}
            required
        />
    </fieldset>
);

const TextAreaField = ({ label, name, value, onChange }) => (
    <fieldset className="description has-top-title">
        <textarea
            name={name}
            value={value}
            onChange={onChange}
            placeholder={label}
            required
        ></textarea>
        <label>{label}</label>
    </fieldset>
);

const FileUpload = ({ selectedFile, onFileChange }) => (
    <div className="col-sm-6">
        <div className="right">
            <label className="uploadfile w-100" style={{ float: 'left' }}>
                <div className="tf-button-primary w-100 style-bg-white">
                    {selectedFile &&
                        <img src={`data:image/jpeg;base64,${selectedFile}`} className='m-0' alt="Selected" style={{ width: '30px', height: '30px' }} />
                    }
                    <input type="file" accept='image/*' onChange={onFileChange} className="" name="file" />
                </div>
            </label>
        </div>
    </div>
);

export default AddPropartyForm;
