import React, { useEffect, useState } from 'react';
import KhaltiCheckout from 'khalti-checkout-web';
import { useNavigate } from 'react-router-dom';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { AddToWallet } from './AddToWallet';

export const KhaltiPayment = ({ product_name, product_amount }) => {
    const { setTransactionId, transactionId } = useContextex();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const snackMessage = 'Your Transaction Is Successful';
    const navigate = useNavigate();

    useEffect(() => {
        const handlePaymentSuccess = (payload) => {
            console.log('Payment successful:', payload);
            setTransactionId(payload.transaction_id);
            setShowSnackbar(true);
            navigate('/dashboard');
        };

        const handlePaymentError = (error) => {
            console.error('Payment error:', error);
            // Handle payment error scenario if needed
        };

        const handleCloseWidget = () => {
            console.log('Widget closed');
            // Handle widget close event if needed
        };

        const config = {
            publicKey: "live_secret_key_68791341fdd94846a146f0457ff7b455",
            productIdentity: Date.now(), 
            productName: product_name,
            productUrl: "http://example.com/product-url",
            eventHandler: {
                onSuccess: handlePaymentSuccess,
                onError: handlePaymentError,
                onClose: handleCloseWidget,
            },
            paymentPreference: ["KHALTI", "EBANKING", "MOBILE_BANKING", "CONNECT_IPS", "SCT"],
        };

        const checkout = new KhaltiCheckout(config);

        const handleClick = () => {
            checkout.show({ amount: product_amount * 100 }); // Khalti expects amount in paisa
        };

        handleClick();

    }, [product_name, product_amount, setTransactionId, navigate]);

    const handleCloseSnackbar = () => setShowSnackbar(false);

    return (
        <>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMessage} />}
            {transactionId && <AddToWallet />}
        </>
    );
};

