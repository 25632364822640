import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchPropGalCat, fetchUserGalleryCat } from '../store/action';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getData } from '../store/selector';

const AddGalleryImage = () => {
    const [itemData, setItemData] = useState([]);
    const [galCatList, setGalCatList] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [selectedProperty, setSelectedProperty] = useState('');
    const [selectedPropertyStatus, setSelectedPropertyStatus] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [defaultSelectedProperty, setDefaultSelectedProperty] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const { isUserId, editSelectedMyGallaryImage, isEditSelectedProperty, imageBaseUrl } = useContextex();
    const dispatch = useDispatch();
    const data = useSelector(getData);
    const galCat = useSelector(state => state.galCat);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(fetchUserGalleryCat({ uid: isUserId }));
    }, [dispatch, isUserId]);

    useEffect(() => {
        if (data?.gallerylist) {
            const groupedItems = groupItems(data.gallerylist);
            setItemData(Object.values(groupedItems));
        }
    }, [data]);

    useEffect(() => {
        if (selectedProperty) {
            dispatch(fetchPropGalCat({ uid: isUserId, prop_id: selectedProperty }));
        }
    }, [dispatch, selectedProperty, isUserId]);

    useEffect(() => {
        if (galCat?.galcatlist) {
            setGalCatList(galCat.galcatlist);
            setSelectedType(galCat.galcatlist.length > 0 ? galCat.galcatlist[0].category_id : '');
        }
    }, [galCat]);

    useEffect(() => {
        if (isEditSelectedProperty && editSelectedMyGallaryImage) {
            setSelectedFile(`${imageBaseUrl}${editSelectedMyGallaryImage.image}`);
            setSelectedProperty(editSelectedMyGallaryImage.property_id);
            setDefaultSelectedProperty(editSelectedMyGallaryImage.property_title);
            setSelectedType(editSelectedMyGallaryImage.category_id);
            setSelectedPropertyStatus(editSelectedMyGallaryImage.status);
        } else {
            resetFormFields();
        }
    }, [isEditSelectedProperty, editSelectedMyGallaryImage]);

    const groupItems = (galleryList) => {
        return galleryList.reduce((acc, item) => {
            if (!acc[item.property_id]) {
                acc[item.property_id] = {
                    property_id: item.property_id,
                    property_title: item.property_title,
                    categories: []
                };
            }
            const categoryExists = acc[item.property_id].categories.some(category => category.category_id === item.category_id);

            if (!categoryExists) {
                acc[item.property_id].categories.push({
                    category_id: item.category_id,
                    category_name: item.category_title.trim()
                });
            }

            return acc;
        }, {});
    };

    const resetFormFields = () => {
        setSelectedFile(null);
        setSelectedProperty('');
        setSelectedType('');
        setSelectedPropertyStatus('');
        setGalCatList([]);
    };

    const handleSelectProperty = (propertyId) => {
        setSelectedProperty(propertyId);
        const property = itemData.find(item => item.property_id.toString() === propertyId);
        setGalCatList(property ? property.categories : []);
        setSelectedType(property ? property.categories[0]?.category_id : '');
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedFile(reader.result.split(',')[1]);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAddImage = async (event) => {
        event.preventDefault();

        try {
            const formData = {
                uid: isUserId,
                prop_id: selectedProperty,
                cat_id: selectedType,
                status: selectedPropertyStatus,
                img: selectedFile || '0',
                record_id: isEditSelectedProperty ? editSelectedMyGallaryImage.id : undefined
            };

            const endpoint = isEditSelectedProperty ? 'update_gallery.php' : 'add_gallery.php';
            await dispatch(fetchData(endpoint, formData));
            setShowSnackbar(!showSnackbar);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <>
            <form className="form-basic-information flex gap30 flex-column" onSubmit={handleAddImage}>
                {/* Property Selector */}
                <div className="nice-select" tabIndex="0">
                    <span className="current">{selectedProperty ? defaultSelectedProperty : 'Property Type'}</span>
                    <ul className="list">
                        {itemData.map((item) => (
                            <li
                                key={item.property_id}
                                className={selectedProperty === item.property_id.toString() ? 'option selected' : 'option'}
                                onClick={() => handleSelectProperty(item.property_id)}
                            >
                                {item.property_title}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Image Upload */}
                <div className="col-sm-12">
                    <div className="input input--secondary">
                        <div className="right">
                            <label className="uploadfile w-100" style={{ float: 'left' }}>
                                <div className="tf-button-primary w-100 style-bg-white">
                                    {selectedFile && (
                                        <img src={`data:image/jpeg;base64,${selectedFile}`} className='m-0' alt="Selected" style={{ width: '30px', height: '30px' }} />
                                    )}
                                    <input type="file" accept='image/*' onChange={handleImageChange} className="" name="file" />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Gallery Category Selector */}
                <div className="nice-select" tabIndex="0">
                    <span className="current">{t('Gallery Category')}</span>
                    <ul className="list">
                        {galCatList.map((item) => (
                            <li
                                key={item.id}
                                className={selectedType === item.id ? 'option selected' : 'option'}
                                onClick={() => setSelectedType(item.id)}
                            >
                                {item.cat_title}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Property Status Selector */}
                <div className="nice-select" tabIndex="0">
                    <span className="current">{selectedPropertyStatus === '0' ? 'Publish' : 'UnPublish'}</span>
                    <ul className="list">
                        <li
                            className={selectedPropertyStatus === '0' ? 'option selected' : 'option'}
                            onClick={() => setSelectedPropertyStatus('0')}
                        >
                            {t('Publish')}
                        </li>
                        <li
                            className={selectedPropertyStatus === '1' ? 'option selected' : 'option'}
                            onClick={() => setSelectedPropertyStatus('1')}
                        >
                            {t('UnPublish')}
                        </li>
                    </ul>
                </div>

                {/* Submit Button */}
                <div className="button-submit mt-10">
                    <button className="tf-button-primary" type="submit">{t('Save')} & {t('Preview')} <i className="icon-arrow-right-add"></i></button>
                </div>
            </form>
             {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={data?.ResponseMsg} />}
        </>
    );
};

export default AddGalleryImage;
