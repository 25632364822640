import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../store/action';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getData } from '../store/selector';

const AddImages = () => {
  const { isUserId, userPropertyList, imageBaseUrl, editSelectedImage } = useContextex();
  const { t } = useTranslation();
  const initialState = {
    selectedFile: editSelectedImage ? `${imageBaseUrl}${editSelectedImage.image}` : null,
    selectedPropertyStatus: editSelectedImage?.status || '',
    selectedPropertyTitle: editSelectedImage?.property_title || '',
    selectedProperty: editSelectedImage?.property_id || '',
    isPanorama: ''
  };

  const [formState, setFormState] = useState(initialState);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormState(prevState => ({
          ...prevState,
          selectedFile: reader.result.split(',')[1]
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddUpdateImage = async (event) => {
    event.preventDefault();
    const { selectedFile, selectedProperty, isPanorama, selectedPropertyStatus } = formState;

    const imageData = {
      uid: isUserId,
      prop_id: selectedProperty,
      img: selectedFile,
      is_panorama: isPanorama,
      status: selectedPropertyStatus,
      record_id: editSelectedImage?.id
    };

    console.log("Submitting data:", imageData); // Debugging line

    const endpoint = editSelectedImage ? 'u_extra_edit.php' : 'u_add_exra.php';

    try {
      await dispatch(fetchData(endpoint, imageData));
      setShowSnackbar(true);
    } catch (error) {
      console.error("Error saving image:", error);
    }
  };

  const handlePropertySelection = (event) => {
    const { dataset, innerText } = event.target;
    setFormState(prevState => ({
      ...prevState,
      selectedProperty: dataset.value,
      selectedPropertyTitle: innerText
    }));
  };

  const handlePropertyStatusChange = (event) => {
    setFormState(prevState => ({
      ...prevState,
      selectedPropertyStatus: event.target.dataset.value
    }));
  };

  const handlePanoramaStatusChange = (event) => {
    setFormState(prevState => ({
      ...prevState,
      isPanorama: event.target.dataset.value
    }));
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const renderPropertyOptions = () => (
    userPropertyList?.map((item) => (
      <li
        key={item.id}
        className={`option ${formState.selectedProperty === item.id ? 'selected' : ''}`}
        onClick={handlePropertySelection}
        data-value={item.id}
      >
        {item.title}
      </li>
    ))
  );

  const renderStatusOptions = () => (
    ['0', '1'].map(value => (
      <li
        key={value}
        className={`option ${formState.selectedPropertyStatus === value ? 'selected' : ''}`}
        onClick={handlePropertyStatusChange}
        data-value={value}
      >
        {value === '0' ? 'Publish' : 'Unpublish'}
      </li>
    ))
  );

  const renderPanoramaOptions = () => (
    ['0', '1'].map(value => (
      <li
        key={value}
        className={`option ${formState.isPanorama === value ? 'selected' : ''}`}
        onClick={handlePanoramaStatusChange}
        data-value={value}
      >
        {value === '0' ? 'No' : 'Yes'}
      </li>
    ))
  );

  return (
    <>
      <form className="form-basic-information flex gap30 flex-column" onSubmit={handleAddUpdateImage}>
        {/* Property Type Selector */}
        <div className="nice-select" tabIndex="0">
          <span className="current">{formState.selectedPropertyTitle || 'Property Type'}</span>
          <ul className="list">
            {renderPropertyOptions()}
          </ul>
        </div>

        {/* Image Upload */}
        <div className="input input--secondary">
          <div className="right">
            <label className="uploadfile w-100" style={{ float: 'left' }}>
              <div className="tf-button-primary w-100 style-bg-white">
                {formState.selectedFile && (
                  <img
                    src={`data:image/jpeg;base64,${formState.selectedFile}`}
                    alt="Selected"
                    style={{ width: '30px', height: '30px' }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className=""
                  name="file"
                />
              </div>
            </label>
          </div>
        </div>

        {/* 360 Images Selector */}
        <div className="nice-select" tabIndex="0">
          <span className="current">360 {t('Images')}</span>
          <ul className="list">
            {renderPanoramaOptions()}
          </ul>
        </div>

        {/* Property Status Selector */}
        <div className="nice-select" tabIndex="0">
          <span className="current">{formState.selectedPropertyStatus === '0' ? 'Publish' : 'Unpublish'}</span>
          <ul className="list">
            {renderStatusOptions()}
          </ul>
        </div>

        {/* Submit Button */}
        <div className="button-submit mt-10">
          <button
            type="submit"
            className="tf-button-primary"
          >
            {t('Save')} & {t('Preview')} <i className="icon-arrow-right-add"></i>
          </button>
        </div>
      </form>
      {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={data?.ResponseMsg} />}
    </>
  );
}

export default AddImages;
