import React, { useState, useEffect } from 'react';
import { useContextex } from '../context/useContext';
import { useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { AddToProductPay, AddToWallet } from './AddToWallet';

export const PayfastPayment = ({ product_amount, product_name,booked_for }) => {
  const {
    bookedOtherUserData,
    loginUserData,
    setTransactionId,
    transactionId,
  } = useContextex();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const snackMessage = 'Your Transaction Is Successful';
  const navigate = useNavigate();

  // Prepare form data for PayFast
  const formData = {
    name_first: bookedOtherUserData?.firstName || loginUserData?.UserLogin?.name,
    email_address: bookedOtherUserData?.email || loginUserData?.UserLogin?.email,
    amount: product_amount,
    item_name: product_name,
  };

  // Constants for PayFast configuration
  const payFastUrl = 'https://sandbox.payfast.co.za/eng/process';
  const merchantId = '10000100';
  const merchantKey = '46f0cd694581a';
  const returnUrl = 'http://localhost:3000/dashboard';
  const cancelUrl = window.location.origin;
  const notifyUrl = 'http://localhost:3000/dashboard';

  // Handle form submission to PayFast
  const handleSubmit = () => {
    const paymentData = {
      merchant_id: merchantId,
      merchant_key: merchantKey,
      return_url: returnUrl,
      cancel_url: cancelUrl,
      notify_url: notifyUrl,
      ...formData,
    };

    postToPayFast(payFastUrl, paymentData);
  };

  // Create and submit the form to PayFast
  const postToPayFast = (url, data) => {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = url;

    Object.keys(data).forEach((key) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  const handlePaymentSuccess = () => {
    setShowSnackbar(true);
    if (booked_for == 'booking' && transactionId ) {
      window.location.href = '/dashboard';
  }
  };

  useEffect(() => {
    handleSubmit();
handlePaymentSuccess()
  }, []);

  return (
    <>
      {showSnackbar && <CustomSnackbar handleClose={() => setShowSnackbar(false)} snack_message={snackMessage} />}
      {booked_for == 'wallet' && transactionId && <AddToWallet/>}
      {booked_for == 'booking' && transactionId && <AddToProductPay/>}
    </>
  );
};

export default PayfastPayment;
