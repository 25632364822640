import React, { useEffect, useState } from 'react';
import { useFlutterwave } from 'react-flutterwave';
import { uid } from 'uid';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { AddToProductPay, AddToWallet } from './AddToWallet';
import { useNavigate } from 'react-router-dom';
import AddProparty from '../addProparty/AddProparty';

export const FlutterwavePayment = ({ product_amount, product_name, product_id, booked_for }) => {
    const { bookedOtherUserData, loginUserData, setTransactionId, isUserId, transactionId } = useContextex();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const snack_message = 'your transaction is Successfull'
    const navigate = useNavigate()

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const config = {
        public_key: 'FLWPUBK_TEST-5760e3ff9888aa1ab5e5cd1ec3f99cb1-X',
        tx_ref: Date.now(),
        amount: product_amount,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd,banktransfer',
        customer: {
            email: bookedOtherUserData?.email || loginUserData?.UserLogin?.email || 'test@gmail.com',
            phonenumber: bookedOtherUserData?.phone || loginUserData?.UserLogin?.mobile,
            name: bookedOtherUserData?.firstName || loginUserData?.UserLogin?.name,
        },
        customizations: {
            title: product_name,
            description: 'Payment for items you want to order',
            logo: 'http://www.quatrixglobal.com/wp-content/uploads/2019/08/logo.png',
        },
        meta: {
            user_id: isUserId,
            order_id: uid(),
            product_id: product_id,
        },
    };

    const handlePayment = useFlutterwave(config);

    useEffect(() => {
        const paymentCallback = async (response) => {
            if (response.status === 'successful') {
                setShowSnackbar(true);
                setTransactionId(response?.transaction_id);
                if (booked_for == 'booking' && transactionId ) {
                    window.location.href = '/dashboard';
                }else if(booked_for == 'package' && transactionId){
                    window.location.href = '/addProparty';
                    // navigate('')
                }
            } else if (response.status === 'cancelled') {
                window.location.href = '/payment-cancel';
            } else {
                console.log('Payment failed.');

            }
        };

        handlePayment({ callback: paymentCallback });

        return () => {
            handlePayment({ callback: null });
        };
    }, [handlePayment, setTransactionId, bookedOtherUserData, loginUserData, isUserId, product_amount, product_name, product_id]);

    return (
        <>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snack_message} />}
            {booked_for == 'wallet' && transactionId && <AddToWallet />}
            {booked_for == 'booking' && transactionId && <AddToProductPay />}
            {booked_for == 'package' && transactionId && <AddProparty/>}
        </>
    );
};
