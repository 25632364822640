import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useContextex } from '../context/useContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { CustomSnackbar } from '../utils/CustomSnackbar';

function SenangpayPayment({ product_amount }) {
  const merchantId = '635160135332883';
  const secretKey = '2909-249';
  const { bookedOtherUserData, loginUserData } = useContextex();
  const navigate = useNavigate();
  const location = useLocation();
  const orderId = Date.now(); // Generate order ID dynamically within the effect

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [paymentError, setPaymentError] = useState(null);

  useEffect(() => {
    const initiatePayment = async () => {
      try {
        const payload = {
          amount: product_amount,
          order_id: orderId,
          detail: `Payment for order ${orderId}`,
          name: bookedOtherUserData?.firstName || loginUserData?.UserLogin?.name,
          email: bookedOtherUserData?.email || loginUserData?.UserLogin?.email,
          phone: bookedOtherUserData?.phone || loginUserData?.UserLogin?.mobile,
          callback: '/payment-success',
          redirect: '/payment-success',
        };

        const response = await axios.post('https://sandbox.senangpay.my/payment/', payload, {
          auth: {
            username: merchantId,
            password: secretKey,
          },
        });

        if (response.data && response.data.payment_url) {
          window.location.href = response.data.payment_url;
        } else {
          console.error('No payment URL found in response:', response.data);
          setPaymentError('Failed to initiate payment');
        }
      } catch (error) {
        console.error('Error initiating payment:', error.message);
        setPaymentError('Error initiating payment');
      }
    };

    if (product_amount) {
      initiatePayment();
    }
  }, [product_amount, bookedOtherUserData, loginUserData, orderId, merchantId, secretKey]);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  useEffect(() => {
    const paymentStatus = new URLSearchParams(location.search).get('status');

    if (paymentStatus === 'successful') {
      setShowSnackbar(true);
      setSnackMessage('Payment successful!');
      navigate('/dashboard'); // Redirect to dashboard after successful payment
    } else if (paymentStatus === 'failed') {
      setShowSnackbar(true);
      setSnackMessage('Payment failed. Please try again.');
    }
  }, [location, navigate]);

  return (
    <div>
      <p>Initiating payment...</p>
      {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMessage} />}
      {paymentError && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={paymentError} />}
    </div>
  );
}

export default SenangpayPayment;