import React, { useEffect, useMemo, useState } from 'react';
import { useContextex } from '../context/useContext';
import Footer from '../partials/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { packagePlan } from '../store/action';
import { ReModal } from '../partials/ReModal';
import { KhaltiPayment } from '../payment/KhaltiPayment';
import { FlutterwavePayment } from '../payment/FlutterwavePayment';
import { PaystackPayment } from '../payment/PaystackPayment';
import { RazorpayPayment } from '../payment/RazorpayPayment';
import { PaytmPayment } from '../payment/PaytmPayment';
import SenangpayPayment from '../payment/SenangpayPayment';
import { MidtransPayment } from '../payment/MidtransPayment';
import { AddToProductPay } from '../payment/AddToWallet';
import { TwoCheckoutPayment } from '../payment/TwoCheckoutPayment';
import PayfastPayment from '../payment/PayfastPayment';
import { MercadoPagoPayment } from '../payment/MercadoPagoPayment';
import { useTranslation } from 'react-i18next';
import { getData } from '../store/selector';

function PackagePurchase() {
    const [packageData, setPackageData] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [paymentTrigger, setPaymentTrigger] = useState(false)
    const { t } = useTranslation();
    const {
        setCurrentPage,
        isUserId,
        userCurrency,
        selectedPackage,
        setSelectedPackage,
        paymentGatwayList,
        imageBaseUrl,
        setSelectedPaymentId,
        selectedPackData, 
        setSelectedPackData,
        selectedPaymentId,
        setSelectedPaymentType,
        selectedPaymentType,
    } = useContextex();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector(getData);

    useEffect(() => {
        const fetchPackagePlans = async () => {
            try {
                await dispatch(packagePlan({ uid: isUserId }));
            } catch (error) {
                console.error("Error fetching package plans:", error);
            }
        };

        fetchPackagePlans();
    }, [dispatch, isUserId]);

    useEffect(() => {
        if (data?.PackageData) {
            setPackageData(data.PackageData);
        }
    }, [data]);

    useEffect(() => {
        setCurrentPage('package_purchase');
    }, [setCurrentPage]);

    const closeModal = () => setIsOpenModal(false);

    const handleClick = (data) => {
        setSelectedPackage(data.id);
        setSelectedPackData(data);

        if (data.price > 0) {
            setIsOpenModal(true);
        } else {
            console.log('Package price is 0 or less; no modal shown.');
        }
    };

    return (
        <>
            <div className='main-content px-20 default'>
                <section className="tf-section flat-pricing">
                    <div className="cl-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading-section text-center">
                                    <h2 className="wow fadeInUp">{t('Choose the right pricing plan for you')}</h2>
                                    <div className="text wow fadeInUp">
                                        {t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            {packageData.map((item) => (
                                <div className="col-xxl-3 col-md-6" key={item.id} onClick={() => handleClick(item)}>
                                    <div className={`pricing-item wow fadeInUp ${selectedPackage === item.id ? 'active' : ''}`} data-wow-delay="0.2s">
                                        <div className="top">
                                            <h4>{item.title}</h4>
                                            <h4>{userCurrency}{item.price}/<span>({item.day} {t('day')})</span></h4>
                                        </div>
                                        <div className="center">
                                            <ul>
                                                <li className="check-ellipse-item">
                                                    <div className="icon">
                                                        <i className="flaticon-check"></i>
                                                    </div>
                                                    <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                                </li>
                                            </ul>
                                            <a
                                                className="tf-button-primary w-full justify-content-center style-bg-white"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents triggering parent onClick
                                                    handleClick(item);
                                                }}
                                            >
                                                {t('Continue')}
                                                <i className="icon-arrow-right-add"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

            {isOpenModal && selectedPackData && selectedPackData.price > 0 && (
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className='p-5 w-100'>
                        <section>
                            <div>
                                <div className='w-100'>
                                    <h4 className="neutral-top pb-5">{t('Select Payment Method')}</h4>
                                    <div className='d-flex row gap-5 align-items-center justify-content-between'>
                                        {paymentGatwayList.map((item) => (
                                            <div key={item?.id} onClick={() => { setSelectedPaymentType(item?.title); setSelectedPaymentId(item?.id) }} className={`categories-item col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 wow fadeInUp ${selectedPaymentId === item?.id ? 'active-map' : ''}`} >
                                                <div className="icon">
                                                    <img src={`${imageBaseUrl}${item.img}`} alt={`${item.title} icon`} />
                                                </div>
                                                <div>
                                                    <div className="name">
                                                        <a>{item.title}</a>
                                                    </div>
                                                    <div className="text">{item.subtitle}</div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="button-submit mt-10">
                                            <button className="tf-button-primary" onClick={() => setPaymentTrigger(true)}>{t('Continue')}<i className="icon-arrow-right-add"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </ReModal>
            )}
            {selectedPaymentType === 'Pay TO Owner' && paymentTrigger && <AddToProductPay />}
            {selectedPaymentType === 'Paytm' && paymentTrigger && <PaytmPayment product_amount={selectedPackData?.price} />}
            {selectedPaymentType === 'SenangPay' && paymentTrigger && <SenangpayPayment product_amount={selectedPackData?.price} />}
            {selectedPaymentType === 'Midtrans' && paymentTrigger && <MidtransPayment product_amount={selectedPackData?.price} />}
            {/* {selectedPaymentType === 'wallet' && walletPay && <AddToProductPay paybleWalletAmnt={selectedPackData?.price}/>} */}
            {selectedPaymentType === '2checkout' && paymentTrigger && <TwoCheckoutPayment product_amount={selectedPackData?.price} />}

            {selectedPaymentType === 'PayStack' && paymentTrigger && <PaystackPayment product_amount={selectedPackData?.price} booked_for={'package'} />}
            {selectedPaymentType === 'Razorpay' && paymentTrigger && <RazorpayPayment product_amount={selectedPackData?.price} booked_for={'package'} />}
            {selectedPaymentType === 'Khalti Payment' && paymentTrigger && <KhaltiPayment product_name={selectedPackData?.title} product_amount={selectedPackData?.price} />}
            {selectedPaymentType === 'MercadoPago' && paymentTrigger && <MercadoPagoPayment product_name={selectedPackData?.title} product_amount={selectedPackData?.price} />}
            {selectedPaymentType === 'Payfast' && paymentTrigger && <PayfastPayment product_name={selectedPackData?.title} product_amount={selectedPackData?.price} booked_for={'package'} />}
            {selectedPaymentType === 'FlutterWave' && paymentTrigger && <FlutterwavePayment product_name={selectedPackData?.title} product_amount={selectedPackData?.price} product_id={selectedPackData.id} booked_for={'package'} />}
        </>
    );
}

export default PackagePurchase;
