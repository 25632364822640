import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useContextex } from '../context/useContext';
import { registerUserDetails } from '../store/action';
import { useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { getAuthTokenData, getLoading, getRegisterData, getRegisterUser } from '../store/selector';
import { useTranslation } from 'react-i18next';

function RegistrationPage() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [registerUser, setRegisterUser] = useState({
        name: '',
        email: '',
        password: ''
    });
    const [showModal, setShowModal] = useState(false);
    const [regUserData, setRegUserData] = useState(null)
    const [showSnackbar, setShowSnackbar] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { login } = useAuth();
    const { t } = useTranslation();
    const { setRegisterModal, registrData, setRegistrData, setIsUser, setUserCurrency, setLoginModal } = useContextex();
    
    const registerUserData = useSelector(getRegisterData);
    const loading = useSelector(getLoading);
    const authToken = useSelector(getAuthTokenData);

    useEffect(() => {
        const modalElement = document.getElementById('modalregister');
        const modal = new Modal(modalElement);
        modal.show();
    }, []);

    useEffect(() => {
        if (registerUserData) {
            console.log('outside Registration Response:', registerUserData);
        }
    }, [registerUserData]);

    useEffect(() => {
        if (registerUserData?.UserLogin || registrData?.UserLogin) {
            const { id, currency } = registerUserData?.UserLogin || registrData.UserLogin;
            localStorage.setItem('uid', id);
            setIsUser(id);
            setUserCurrency(currency);
            setRegistrData(registerUserData);
        }
    }, [registerUserData, registrData, setIsUser, setUserCurrency, setRegistrData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRegisterUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const Ccode = phoneNumber.slice(0, 2);
        const countryCallingCode = '+'.concat(Ccode);
        const mobileNumber = phoneNumber.slice(2);

        try {
            await dispatch(registerUserDetails({
                name: registerUser.name,
                email: registerUser.email,
                mobile: mobileNumber,
                ccode: countryCallingCode,
                password: registerUser.password
            }));
            localStorage.setItem('loginUser', JSON.stringify(registerUserData || registrData));
            setShowSnackbar(true);
            if (registerUserData.ResponseCode == 200 || registrData.ResponseCode == 200) {
                navigate('/')
                setRegisterModal(false)
                setLoginModal(false);
                login(authToken)
            }

        } catch (error) {
            console.error("Error during registration:", error);
        }
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const snackMessage = registerUserData?.ResponseMsg

    return (
        <>
            <div className="modal fade modalCenter" id="modalregister">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal-sm">
                        <a className="btn-hide-modal pointer" data-bs-dismiss="modal">
                            <i className="icon-close pointer"></i>
                        </a>
                        <div className="image-left">
                            <img src="./assets/images/section/login.jpg" alt="" />
                            <h3>{t('Welcome to Your Real Estate Website')}</h3>
                        </div>
                        <div className="content-right">
                            <h4>{t('Create an account')}</h4>
                            <form className="form-login" onSubmit={handleSubmit}>
                                <fieldset className="name">
                                    <input
                                    className={!registerUser.name && 'border border-success'}
                                        tabIndex="2"
                                        aria-required="true"
                                        type='text'
                                        placeholder="Full Name"
                                        name="name"
                                        value={registerUser.name}
                                        onChange={handleChange}
                                    />
                                    {!registerUser.name && <span className='text-danger'>*Please Enter Name</span>}
                                </fieldset>
                                <fieldset className="email">
                                    <input
                                    className={!registerUser.email && 'border border-success'}
                                        type='email'
                                        placeholder="Email Address"
                                        name="email"
                                        value={registerUser.email}
                                        onChange={handleChange}
                                        tabIndex="2"
                                        aria-required="true"
                                    />
                                    {!registerUser.email && <span className='text-danger'>*Please Enter Email</span>}
                                </fieldset>
                                <fieldset className="phonenumber">
                                    <input
                                        className={!phoneNumber && 'border border-success'}
                                        type='text'
                                        placeholder="Phone Number"
                                        name="phone"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        maxLength={12}
                                        minLength={12}
                                        tabIndex="2"
                                        aria-required="true"
                                    />
                                    {!phoneNumber && <span className='text-danger'>*Please Enter Phone number</span>}
                                </fieldset>
                                <fieldset className="password">
                                    <input
                                        className={!registerUser.password && 'border border-success'}
                                        type={"password"}
                                        placeholder="Password"
                                        name="password"
                                        value={registerUser.password}
                                        onChange={handleChange}
                                        tabIndex="2"
                                        aria-required="true"
                                    />
                                    {!registerUser.password && <span className='text-danger'>*Please Enter Password</span>}
                                </fieldset>
                                <fieldset className="password">
                                    <input
                                        tabIndex="2"
                                        aria-required="true"
                                        type='text'
                                        placeholder="Referral code (optional)"
                                    />
                                </fieldset>
                                <div className="flex items-center justify-between">
                                    <div className="checkbox-item">
                                        <label>
                                            <p>{t('I agree with terms & conditions')}</p>
                                            <input type="checkbox" />
                                            <span className="btn-checkbox"></span>
                                        </label>
                                    </div>
                                </div>
                                {/* {!registerUser.password && <span className='text-danger'>*Please Select terns & condition </span>} */}
                                <div className="button-submit">
                                    <button className="tf-button-primary w-full justify-content-center" type="submit">
                                        {t('Register')}
                                        <i className="icon-arrow-right-add"></i>
                                    </button>
                                </div>
                            </form>
                            <div className="flex items-center justify-center">
                                <p>{t('Have an account')}?</p>
                                <a
                                    className="btn-show-register pointer"
                                    data-bs-dismiss="modal"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modallogin"
                                >
                                    {t('Log in')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMessage} />}
        </>
    );
}

export default RegistrationPage;
