import React, { useEffect } from 'react';
import axios from 'axios';

export const TwoCheckoutPayment = ({ product_amount }) => {
  const sellerId = '250507228545'; // Replace with your 2Checkout Seller ID
  const secretKey = '=+0CNzfvTItqp*ygwiQE'; // Replace with your 2Checkout Secret Key
  const orderId = Date.now();

  useEffect(() => {
    const initializePayment = async () => {
      try {
        const payload = {
          sellerId,
          privateKey: secretKey,
          merchantOrderId: orderId,
          token: {
            currency: 'USD', // Currency code (e.g., USD)
            value: product_amount * 100, // Amount in cents or smallest unit of the currency
          },
          returnUrl: 'http://localhost:3000/checkout/success', // Replace with your success return URL
          paymentMethod: {
            type: 'ECP', // Payment method type (e.g., ECP for credit card)
          }
        };

        // Make API request to 2Checkout using proxy
        const response = await axios.post('/api/checkout/api/1/create_order', payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Redirect to 2Checkout payment URL
        window.location.href = response.data.checkout_url;
      } catch (error) {
        console.error('Error initiating payment:', error);
        // Handle error (e.g., display error message to user)
      }
    };

    initializePayment();
  }, [product_amount, orderId, sellerId, secretKey]);

  return (
    <div>
      <p>Initiating payment...</p>
    </div>
  );
};
